import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter,Routes, Route } from "react-router-dom"
import { AuthProvider } from './context/AuthProvider';
import { SpotProvider } from './context/SpotProvider';
import { ToastContainer } from 'react-toastify';
import { MessageProvider } from './context/MessageProvider';

/*
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true, // Tracks functional components
  });
}
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    
    <MessageProvider>
    <AuthProvider>
      <SpotProvider>                
          <Routes>
            <Route path="/*" element={<App/>} />
          </Routes>                        
      </SpotProvider>
    </AuthProvider>
    </MessageProvider>
    </BrowserRouter>
  </React.StrictMode>
);


