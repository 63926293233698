import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Konva from 'konva';
import {useAuth} from '../context/AuthProvider';
import { useSpotContext } from '../context/SpotProvider';
import { SpotMenu } from '../components/SpotMenu';


var width = window.innerWidth;
var height = window.innerHeight;
var scaleMultiplier = 0.8;
var lastDist = 0;

const COLOUR_FREE = "#66ff00";
const COLOUR_PRERESERVED = "orange";
const COLOUR_RESERVED = "red";
const COLOUR_PAYED = "blue";
const COLOUR_OWN = "#40E0D0";

var stage = null;
//resizeCanvas();
var layer;
var imageObj;
var layer2;
var textLayer;
var spotPoly = {};

/*

*/

let history;
//let selectedSpot;
let infoText;

const SPOT_STATUS_LOCKED = process.env.REACT_APP_SPOT_STATUS_LOCKED;
const SPOT_STATUS_FREE = process.env.REACT_APP_SPOT_STATUS_FREE;
const SPOT_STATUS_PREORDERED = process.env.REACT_APP_SPOT_STATUS_PREORDERED;
const SPOT_STATUS_ORDERED = process.env.REACT_APP_SPOT_STATUS_ORDERED;
const SPOT_STATUS_INVOICED = process.env.REACT_APP_SPOT_STATUS_INVOICED;
const SPOT_STATUS_PAYED = process.env.REACT_APP_SPOT_STATUS_PAYED;

//let handleClickOpen = null;
//let handleClose = null;

const emails = ['username@gmail.com', 'user02@gmail.com'];

const MapPage = () => {
    const [showSpots, setShowSpots] = useState('Kaikki'); 
    const navigate = useNavigate();    
    const [sizeFilter, setSizeFilter] = useState([]);
    const [filteredSpots, setFilteredSpots] = useState([]);
    const [loaded, setLoaded] = useState(false);  

    //const [selectElectricityDialogOpen, setSelectElectricityDialogOpen] = useState(false);
    //const [selectedValue, setSelectedValue] = useState(emails[1]);    
    const {spots, getOrders, lockSpot, unlockSpot} = useSpotContext();    

    const { isAuthenticated, isAdmin, auth } = useAuth();


    let getSpot = (spotID) => {
        
        return spots.find(spot => spot.spotID === spotID);
        
    }    

    let addToReservation = (aPoly) => {

        const spot = getSpot(aPoly.attrs['id']);

        lockSpot(spot,auth.user.userID,SPOT_STATUS_LOCKED);
        //dispatch(addReservation(place)); 
    }

    let removeFromReservation = (aPoly) => {
        const spot = getSpot(aPoly.attrs['id']);

        unlockSpot(spot,SPOT_STATUS_FREE);
        //dispatch(removeReservation(place));   
    }

    /*
    const handleClose = (value) => {
        setSelectElectricityDialogOpen(false);
        setSelectedValue(value);
    };
    */

    /*
    const handleListItemClick = (value) => {
        setSelectElectricityDialogOpen(value);
    };
    */

    //const imageRef = useRef(null);
    const canvasRef = useRef(null);
    infoText = useRef(null);
    

    /*
    const is230V = () => {
        return e230V;
    }

    const is400V = () => {
        return e400V;
    }

    const setElectricity230 = () => {
        setE230(!e230V);
    }

    const setElectricity400 = () => {
        setE400(!e400V);
    }
    */

    /*
    handleClickOpen = () => {
        //setSelectElectricityDialogOpen(true);
    };
    */


    //isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    //user = useSelector(state => state.auth.user);
    //const places = useSelector(state => state.places);

    //const dispatch = useDispatch();

    useEffect(() => {
        //let isMounted = true;

        if (!spots.isLoading) {
            if ((!spots || spots.length <= 0)) {
                //loadMap(dispatch);
            } else if (!canvasRef.current.firstChild) {                                

                stage = getStage(width, height);
                
                //resizeCanvas();
                layer = new Konva.Layer();
                stage.add(layer);
                imageObj = getLMMapImage();
                layer2 = new Konva.Layer();
                //layer.hide();
                //layer2.zIndex(3);

                const ctx = canvasRef.current.getContext("2d");

                //ctx.drawImage(imageRef.current, 0, 0);
                ctx.drawImage(imageObj, 0, 0);

                infoText.current.innerHTML = getInfoTable(null);
                                
                var processedSpots = [];                                
                spots.forEach(place => {
                //Array.prototype.forEach.call(places.places, place => {
                    if(!processedSpots.includes(place.spotID)) {
                     //if ((!is230V() && !is400V()) || (place.electric230v === "1" && is230V()) || (place.electric400v === "1" && is400V())) { 
                        //addPlace(place.spotID, place.name, place.coordinates, place.status, false, null, place.size, place.prize, place.electric230v, place.electric400v, layer2, COLOUR_FREE, COLOUR_PRERESERVED, COLOUR_RESERVED,COLOUR_PAYED, COLOUR_OWN);
                        addSpot(place,layer2);
                        processedSpots.push(place.spotID);
                    //}
                    }
                })                


                                
                stage.add(layer2);

                window.addEventListener("resize", resizeCanvas);
                resizeCanvas(); // Set initial size                            
            }
        } else if (stage) {
            //resizeCanvas(); TL_2025
        }
        
    
        /*
        getOrders();
    
        const interval = setInterval(() => {
          getOrders();
        }, 300000); // 10 seconds
    
        return () => clearInterval(interval); // Cleanup on unmount
        */
        //return () => {
        //    isMounted = false;
        //    controller.abort();
        //}
      //},[spots]); //TL_2025

        return () => window.removeEventListener("resize", resizeCanvas);

        },[loaded]);
  

    const spotsLoaded = () => {
        setLoaded(true);
    }

    useEffect(() => {
    
        getOrders(spotsLoaded);

        const interval = setInterval(() => {
            getOrders(spotsLoaded);            
        }, 60000); // 10 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    },[]);      

    useEffect(() => {

        console.log("Get filtered spots");

        let filtered = spots.filter(s => showSpots === "Kaikki" || (showSpots === "Vapaat" && (s.status === SPOT_STATUS_FREE || s.status === SPOT_STATUS_LOCKED)) || 
        (showSpots === "Varatut" && (s.status === SPOT_STATUS_ORDERED ||  s.status === SPOT_STATUS_INVOICED || s.status === SPOT_STATUS_PAYED || s.status === "Maksu paikalla")) || (s.userID === auth.user?.userID && ((showSpots === "Valitut" && (s.status === SPOT_STATUS_LOCKED || s.status === SPOT_STATUS_PREORDERED)) || (showSpots === "Omat"))));

        filtered = sizeFilter.length
        ? filtered.filter((spot) => sizeFilter.some(item => item.size.includes(spot.size))).map(spot => spot.spotID)
        : filtered.map(spot => spot.spotID); // If no sizes selected, show all spots

        setFilteredSpots(filtered);

    },[showSpots, sizeFilter, spots]);    

    function selectPlace(aPoly, aLayer) {
        if (!isAuthenticated) {
            history.push("/login");
        } else {
            if (aPoly.selected) {
                aPoly.setFill(COLOUR_FREE);
                aPoly.selected = false;
                removeFromReservation(aPoly)
            } else {
                //handleClickOpen(aPoly);                    
                aPoly.setFill(COLOUR_OWN);
                aPoly.selected = true;
                addToReservation(aPoly);
            }
        }
    }



    function getStage(aWitdth, aHeight) {

        Konva.hitOnDragEnabled = true;
    
        var stage = new Konva.Stage({
            container: 'mapContainer',
            preventDefault: false,
            height: aHeight,
            width: aWitdth,
            scaleX: 1,
            scaleY: 1,
            draggable: true
        });
    
        stage.on('wheel', function (event) {
            var touchPos = stage.getPointerPosition();
            var bx = stage.getX();
            var by = stage.getY();
            var bsx = stage.getScaleX();
            var bsy = stage.getScaleY();
            if (event.evt.deltaY < 0) {
                if (stage.getScaleX() > 10) {
                    return;
                }
                stage.setScaleX(stage.getScaleX() / scaleMultiplier);
                stage.setScaleY(stage.getScaleY() / scaleMultiplier);
                stage.draw();
            } else {
                var newScale = stage.getScaleX() * scaleMultiplier;
    
                if (newScale < stage.minScale) {
                    newScale = stage.minScale;
                }
                stage.setScaleX(newScale);
                stage.setScaleY(newScale);
                stage.draw();
            }
    
            var newX = touchPos.x - stage.getScaleX() * (touchPos.x - bx) / bsx;
            var newY = touchPos.y - stage.getScaleY() * (touchPos.y - by) / bsy;
    
    
            stage.setX(newX);
            stage.setY(newY);
            //relocateCanvas(false); 
            stage.draw();
        });
    
        stage.on("dragend", function(e) {        
            relocateCanvas(true);        
        });
    
        stage.on('touchmove', function (e) {
            e.evt.preventDefault();
    
            var bx = stage.getX();
            var by = stage.getY();
            var bsx = stage.getScaleX();
            var bsy = stage.getScaleY();
    
            var touch1 = e.evt.touches[0];
            var touch2 = e.evt.touches[1];
    
            if (touch1 && touch2) {
                stage.multitouch = true;
    
                var dist = getDistance(
                    {
                        x: touch1.clientX,
                        y: touch1.clientY
                    },
                    {
                        x: touch2.clientX,
                        y: touch2.clientY
                    }
                );
    
                if (!lastDist) {
                    lastDist = dist;
                }
    
                var scale = (stage.scaleX() * dist) / lastDist;
    
                stage.scaleX(scale);
                stage.scaleY(scale);
    
                var tx = (touch1.clientX + touch2.clientX) / 2;
                var ty = (touch1.clientY + touch2.clientY) / 2;
    
    
                var newX = tx - scale * (tx - bx) / bsx;
                var newY = ty - scale * (ty - by) / bsy;
    
                stage.setX(newX);
                stage.setY(newY);
    
                stage.draw();
                lastDist = dist;
            }
        });
    
        stage.on('touchend', function () {
            lastDist = 0;
        });
    
        stage.on('dbltap', function () {
            if (stage.multitouch) {
                stage.multitouch = false;
                return;
            }
            stage.setDraggable(!stage.getDraggable());                
        });
    
        //window.addEventListener('resize', resizeCanvas, false); TL_2025
        return stage;    
    }
    
    function getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }
    
    function resizeCanvas() {        
        if(stage) {
            height = window.innerHeight - 64;
            width = window.innerWidth;
            stage.setWidth(width);
            stage.setHeight(height);
            CenterAndScaleStage();
            stage.draw();
        }
    }
    
    function CenterAndScaleStage() {
        var scale1 = height / imageObj.height;
        var scale2 = width / imageObj.width;
    
        if (scale1 < scale2) {
            stage.setScaleX(scale1);
            stage.setScaleY(scale1);
            stage.setY(0);
    
            stage.setX((width - (imageObj.width) * scale1) / 2);
    
        } else {
            stage.setScaleX(scale2);
            stage.setScaleY(scale2);
            stage.setX(0);
            //stage.setY((height - (imageObj.height) * scale2) / 2);
            stage.setY(0);
        }
        stage.minScale = stage.getScaleX();
    }
    
    function getLMMapImage() {
        var image = new Image();
        image.onload = function () {
            var kartta = new Konva.Image({
                x: 0,
                y: 0,
                image: imageObj
            });
    
            if (imageObj) {
                var scale1 = height / imageObj.height;
                var scale2 = width / imageObj.width;
    
                if (scale1 < scale2) {
                    stage.setScaleX(scale1);
                    stage.setScaleY(scale1);
                    stage.setY(0);
    
                    stage.setX((width - (image.width) * scale1) / 2);
    
                } else {
                    stage.setScaleX(scale2);
                    stage.setScaleY(scale2);
                    stage.setX(0);
                    //stage.setY((height - (image.height) * scale2) / 2);
                    stage.setY(0);
                }
                stage.minScale = stage.getScaleX();
            }
    
    
            // add the shape to the layer
            layer.add(kartta);            
            stage.batchDraw();
        };
        //image.src = 'https://www.lcmynamaki.fi/wp-content/kartta.svg';
        image.src = 'https://lauri.lcmynamaki.fi/kartta_2025.svg';
    
        return image;
    }
    
    
    
    
    function comparePaikat(p1, p2) {
    
        var p1_temp = p1.match(/(\d+)/);
        var p2_temp = p2.match(/(\d+)/);
    
        return parseInt(p1_temp) - parseInt(p2_temp);
    
    }

    function relocateCanvas(draw) {
        var stageW = stage.scaleX() * imageObj.width;        
        var stageH = stage.scaleX() * imageObj.height; 
        var update = false;
        if(stage.getX() + stageW <= width && stage.getX() <= 0) {
            if(stageW > width) {
                stage.setX(width-stageW);
            } else {
                stage.setX(0);
            }
            update = true;
        }
        if(stage.getY() + stageH <= height && stage.getY() <= 0) {
            if(stageH > height) {
                stage.setY(height-stageH);
            } else {
                stage.setY(0);
            }
            update = true;
        }
        if(stage.getX() + stageW > width && stage.getX() > 0 ) {
            if(stageW > width) {
                stage.setX(0);
            } else {
                stage.setX(width-stageW);
            }
            update = true;
        }
        if(stage.getY() + stageH > height && stage.getY() > 0) {
            if(stageH > height) {
                stage.setY(0);
            } else {
                stage.setY(height-stageH);
            }
            update = true;
        }
    
        if(update && draw) {
            stage.draw();
        }
    }
    
    
    /*
    function removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }
    */
    
    function getBgColour(status,userID) {

        if(auth.user?.userID === userID) {
            return COLOUR_OWN;       
        }

        switch(status) {
            case "Ennakkovaraus": return COLOUR_PRERESERVED;
            case "Varattu": return COLOUR_RESERVED;
            case "Laskutettu": return COLOUR_RESERVED;
            case "Maksettu": return  COLOUR_PAYED;     
            case SPOT_STATUS_LOCKED: 
                if(auth.user?.userID === userID) {
                    return COLOUR_OWN;       
                } else {
                    return COLOUR_PRERESERVED;
                }
            default: return COLOUR_FREE;
        }
    }

        
    
     

    function  addSpot(spot, aLayer) {
            
        let coordinates;
        let omaVaraus = false;
        
        if(auth?.user && spot.userID === auth.user?.userID) {            
            omaVaraus = true;
        }
        
    
        if (!Array.isArray(spot.coordinates)) {
            let tmp = spot.coordinates.replace("[", "");
            tmp = tmp.replace("]", "");
            coordinates = tmp.split(`,`).map(x => +x);
        } else {
            coordinates = spot.coordinates;
        }
    
        var bgColor = getBgColour(spot.status,spot?.userID);
    
        if (omaVaraus) {
            bgColor = COLOUR_OWN;
        }
    
        var electricity = "Ei";
    
        if (spot.electric230v === "1" && spot.electric400v === "1") {
            electricity = "230V / 400V";
        } else if (spot.electric230v === "1") {
            electricity = "230V";
        } else if (spot.electric400v === "1") {
            electricity = "400V";
        }
    
        var aPoly = new Konva.Line({            
            id: spot.spotID,
            name: spot.name,
            koko: spot.size,
            hinta: spot.prize,
            points: coordinates,
            status: spot.status,
            electricity: electricity,
            fill: bgColor,
            stroke: 'black',
            strokeEnabled: false,
            opacity: 0.5,
            closed: true
        });
    
        if (omaVaraus) {
            aPoly.selected = true;
        }
    
        var aPolyLine = new Konva.Line({
            points: coordinates,
            fill: bgColor,
            stroke: 'black',
            fillEnabled: false,
            strokeWidth: 1,
            //opacity: 0.7,
            closed: true
        });

        spotPoly[spot.id] = aPoly;
        spotPoly[spot.id + "line"] = aPolyLine;
    
        if (auth?.user && (spot.status === 'Vapaa' || omaVaraus)) {
            aPoly.on('mouseout', function () {
                //document.getElementById("info").innerHTML = "";
                document.body.style.cursor = "default"; 
                infoText.current.innerHTML = getInfoTable(null);
                aPolyLine.setStroke('black');
                aLayer.draw();
            });
    
            aPoly.on('mouseover', function () {
                //selectedSpot = aPoly;
                //document.getElementById("infoText").innerHTML = aPoly.getName();
                document.body.style.cursor = "pointer";
                infoText.current.innerHTML = getInfoTable(aPoly);
                /*
                "<table>" +
                    "<tr><td><strong>Paikka:</strong></td><td>" + aPoly.getName() + "</td></tr>" +
                    "<tr><td><strong>Koko:</strong></td><td>" + aPoly.attrs['koko'] + "</td></tr>" +
                    "<tr><td><strong>Hinta:</strong></td><td>" + aPoly.attrs['hinta'] + " €</td></tr>" +
                    "<tr><td><strong>Tila:</strong></td><td>" + aPoly.attrs['status'] + "</td></tr>" +
                    "</table>";
                    */
                //Paikka: { selectedSpot ? selectedSpot['hinta'] : '' } <br />
                //Koko: <br />
                //Hinta: <br />
                //document.getElementById("info").innerHTML = getInfoTable(aPoly.getName(), aPoly.attrs['koko'], aPoly.attrs['hinta']);
                aPolyLine.moveToTop();
                aPolyLine.setStroke('yellow');
                aLayer.draw();
            });
    
    
            aPoly.on('click', function () {
                selectPlace(aPoly, aLayer);
            });
    
            aPoly.on('tap', function () {
                selectPlace(aPoly, aLayer);
            });
    
            aPoly.on('touchmove', function () {                                
                infoText.current.innerHTML = getInfoTable(aPoly);
                //document.getElementById("info").innerHTML = getInfoTable(aPoly.getName(), aPoly.attrs['koko'], aPoly.attrs['hinta']);
                aPolyLine.moveToTop();
                aPolyLine.setStroke('yellow');
                aLayer.draw();
                document.body.style.cursor = "pointer";
    
            });
    
            aPoly.on('touchend', function () {
                document.body.style.cursor = "default";
                //document.getElementById("info").innerHTML = "";
                infoText.current.innerHTML = getInfoTable(null);
                aPolyLine.setStroke('black');
                aLayer.draw();
            });
    
        }
    
        aLayer.add(aPoly);
        aLayer.add(aPolyLine);
    }

    
    function getInfoTable(spot) {
    
        if(!auth?.user) {
            return null;
        }
        
        var table = "<table>" +
            "<tr><td><strong>Paikka:</strong></td><td>" + (spot ? spot.getName() : "") + "</td></tr>" +
            //"<tr><td><strong>Paikka:</strong></td><td>" + (spot ? spot.attrs['id'] : "") + "</td></tr>" +
            "<tr><td><strong>Koko:</strong></td><td>" + (spot ? spot.attrs['koko'] : "") + "</td></tr>" +
            "<tr><td><strong>Hinta:</strong></td><td>" + (spot ? spot.attrs['hinta'] + " €" : "") + "</td></tr>" +
            "<tr><td><strong>Tila:</strong></td><td>" + (spot ? spot.attrs['status'] : "") + "</td></tr>" +
            //"<tr><td><strong>Sähkö:</strong></td><td>" + (spot ? spot.attrs['electricity'] : "") + "</td></tr>" +
            //"<tr><td><input type='checkbox'  value='230V' " + (is230V() ? "checked" : "") + " onClick='(e) => {setElectricity230();}'>230V</input></td>" +
            //"<td><input type='checkbox'  value='400V' " + (is400V() ? "checked" : "") + ">400V</input></td></tr>" +
            "</table>";
        return table;
    }
    
      
    function postCall(path, params, method = 'post') {
     
        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
     
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
     
                form.appendChild(hiddenField);
            }
        }
     
        document.body.appendChild(form);
        form.submit();
    }               

    return (
        <div className='w-full m-0'>
            <SpotMenu spots={spots} showSpots={showSpots} setShowSpots={setShowSpots} sizeFilter={sizeFilter} setSizeFilter={setSizeFilter} isMapView={true} ></SpotMenu>
            <div className={`${auth.user ? "fixed top-32 left-2 border-2 border-green-500 rounded-md z-20 bg-white/50 p-0" : "hidden"}`}>
                <div ref={infoText} className='classes.infoText' key="caption"></div>                
            </div>            
            <div className="fixed bottom-4 left-1/2 text-xs font-bold transform -translate-x-1/2 flex space-x-4 z-20">
                <div className="border-2 rounded-md z-30 bg-free/60 p-1">Vapaa</div>
                <div className="border-2 rounded-md z-30 bg-preorder/60 p-1">Ennakko</div>
                <div className="border-2 rounded-md z-30 bg-ordered/60 p-1">Varattu</div>
            </div>
            <div id="mapContainer" className='mt-[58px]'>

                <canvas ref={canvasRef} className='classes.canvas' />
                
                {layer2 && spots && spots.length > 0 ? 
                    
                    spots.forEach(spot => {

                        if(!filteredSpots.includes(spot.spotID)) {
                            spotPoly[spot.id].setListening(false);
                            spotPoly[spot.id].setFill("white");
                        } else if(showSpots === "Kaikki" || (showSpots === "Vapaat" && (spot.status === SPOT_STATUS_FREE || 
                            (spot.status === SPOT_STATUS_LOCKED && ((auth && auth.user?.userID === spot?.userID) || !auth))))) {                            
                            spotPoly[spot.id].setListening(spot.status === SPOT_STATUS_FREE || (spot.status === SPOT_STATUS_LOCKED && spot.userID === auth.user?.userID));
                            spotPoly[spot.id].setFill(getBgColour(spot.status,spot.userID));
                        } else if(auth &&  (spot.userID === auth.user?.userID && (showSpots === "Valitut" && spot.status === SPOT_STATUS_LOCKED) || 
                            (showSpots === "Omat" && spot.userID === auth.user?.userID))) {
                                spotPoly[spot.id].setListening(spot.status === SPOT_STATUS_FREE || (spot.status === SPOT_STATUS_LOCKED && spot.userID === auth.user.userID));
                                spotPoly[spot.id].setFill(getBgColour(spot.status,spot.userID));    
                        } else if((showSpots === "Varatut" && (spot.status === SPOT_STATUS_ORDERED ||  spot.status === SPOT_STATUS_INVOICED || spot.status === SPOT_STATUS_PAYED || spot.status === "Maksu paikalla"))) {
                            spotPoly[spot.id].setListening(false);
                            spotPoly[spot.id].setFill(getBgColour(spot.status,spot.userID));  
                        } else {
                            spotPoly[spot.id].setListening(false);
                            spotPoly[spot.id].setFill("white");
                        }
                }) :
                null
                }
            </div >
            
        </div >
    );

}



export { MapPage as default };

