import React from 'react'

export const Contacts = () => {
  return (
    <div className="m-10 rounded-3xl bg-white py-10 text-center">
        <h1 className="text-2xl mb-6 font-bold">Yhteystietoja</h1>
        
        <h1 className="text-2xl font-bold">Markkinavastaava</h1>
        <h1 className="text-md font-bold">Turvallisuusvastaava</h1>
        <div className="">
        <div>Matti Vira</div>
        <div>0400 995 765</div>         
        <div><a className="text-blue-500" href="mailto:matti.vira@gmail.com">matti.vira@gmail.com</a></div>
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>
        
        <h1 className="text-2xl font-bold mt-4">SPR</h1>
        <h1 className="text-md font-bold">Ensiapuvastaava</h1>
        <div className="">
        <div>Elina Junnila</div>
        <div>050 342 8683</div>        
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>
        
        <h1 className="text-2xl font-bold mt-4">Paikkamyynti</h1>
        <div className="">
        <div>Tommi Lautanala</div>
        <div>050 363 9848</div>
        <div><a className="text-blue-500" href="mailto:laurinmarkkinat@lcmynamaki.fi">laurinmarkkinat@lcmynamaki.fi</a></div>
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>
        
        <h1 className="text-2xl font-bold mt-4">Sähkövastaava</h1>
        <div className="">
        <div>Jari Lehto</div>
        <div>040 544 8172</div>        
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>
        
        <h1 className="text-2xl font-bold mt-4">Lions Club Mynämäki ry</h1>
        <h1 className="text-md font-bold">Presidentti</h1>
        <div className="">
        <div>Kaj Kynäslahti</div>
        <div>040 841 4496</div>
        <div><a className="text-blue-500" href="mailto:kaitsukky@gmail.com">kaitsukky@gmail.com</a></div>
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>
        
        <h1 className="text-2xl font-bold mt-4">Mynämäen kunta</h1>
        <h1 className="text-md font-bold">Terveystarkastaja</h1>
        <div className="">
        <div>Riitta Laaksonen</div>
        <div>0400 293 887</div>         
        <div><a className="text-blue-500" href="mailto:riitta.laaksonen@uusikaupunki.fi">riitta.laaksonen@uusikaupunki.fi</a></div>
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>

        <h1 className="text-2xl font-bold mt-4">Yleinen hätänumero</h1>        
        <div className="">        
        <div className="text-red-600 text-3xl font-bold">112</div>
        </div>

        <div className="p-4 border-b justify-center m-[auto] w-[80%] border-gray-600"/>

        <h1 className="text-2xl font-bold mt-4">Sivuston ylläpito</h1>        
        <div className="">                
        <div><a className="text-blue-500" href="mailto:contact@it-lato.fi">contact@it-lato.fi</a></div>
        </div>        
    </div>
  )
}
