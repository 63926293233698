import React, {useState, useEffect} from 'react'
import { useSearchParams } from "react-router-dom";
import useAxios from '../api/axios';
import SpotCard from '../components/SpotCard';
import { toast } from 'react-toastify';
import { useSpotContext } from '../context/SpotProvider';
import { Button } from '../components/Button';


export const ComfirmPreorder = () => {
    const [searchParams] = useSearchParams();
    const preOrderID = searchParams.get("varaus");
    const [loaded, setLoaded] = useState(false);    
    const [errMsg, setErrMsg] = useState(); 
    const [orderDone, setOrderDone] = useState(false);
    //const [orders, setOrders] = useState([]);
    const [filteredSpots, setFilteredSpots] = useState([]);

    const {spots, getPreOrders, updateSpot} = useSpotContext();

    const api = useAxios();

    useEffect(() => {            
        getPreOrders(preOrderID, setLoaded, setErrMsg);        
      },[]);

      useEffect(() => {            
        setFilteredSpots(spots.filter(spot => spot.status === 'Ennakkovaraus'));      
      },[spots]);


    const comfirm = async () => {
             try {                                 
                  const response = await api.post('/makeReservation',
                      JSON.stringify({ companyID: filteredSpots[0].companyID, spots: filteredSpots }),
                      {
                          headers: { 'Content-Type': 'application/json'},                    
                      }
                  );
                                  
                  filteredSpots.forEach(spot => {
                    spot.status = 'Varattu';                    
                    spot.locked = null;                
                    updateSpot(spot);
                  })                            
                  setOrderDone(spots);                           
              } catch(err) {
                  if(!err?.response) {
                      toast.error('Odottomaton virhe');
                      //setErrMsg("Ei vastausta palvelimelta");
                  } else if(err.response?.status === 400) {
                      toast.error("Palvelin virhe");
                      //setErrMsg("Palvelin virhe");
                  } else if(err.response?.status === 401) {
                      toast.error("Tunnukset eivät kelpaa");
                      //setErrMsg("Tunnukset eivät kelpaa");
                  } else {
                      toast.error("Tietojen päivitys epäonnistui");
                      //setErrMsg("Kirjautuminen epäonnistui");
                  }
                  //errRef.current.focus();
              }        
    }

    return (
    <div>
        
        
        {orderDone ?   
        /* Order DONE */   
        <>
            
            <h1 className="text-2xl text-blue-800 text-center mt-4">Tervetuloa markkinoille Mynämäelle!</h1>
            <h1 className="text-sm text-white text-center mt-4">Valitsemasi paikat on nyt varattu sinulle.</h1>
            <h1 className="text-sm text-white text-center mt-4">Lähetämme laskun sähköpostilla laskutusosoitteeseesi.</h1>
            <h1 className="text-sm text-white text-center mt-4">Muista maksaa lasku eräpäivään mennessä pitääksesi varauksesi voimassa</h1>
            <h1 className="text-md text-white text-center mt-4">Kiitos ja nähdään markkinoilla elokuussa.</h1>
            
        </>
        : <> 
        {errMsg ? 
        <>
            <h1 className="text-2xl text-blue-800 text-center mt-4">Vahvista tai peruuta ennakkovarauksesi</h1>    
            <h1 className="text-sm text-white text-center mt-4">{errMsg}</h1>
            <h1 className="text-sm text-white text-center mt-4">Voit kirjautua ja katsoa löytyykö ennakkotilaus omista tilauksistasi.</h1>
        </>
        : <>    
        <h1 className="text-2xl text-blue-800 text-center mt-4">Vahvista tai peruuta ennakkovarauksesi</h1>
        {loaded && filteredSpots.length === 0 ?
        
        /* All canceled */ 
        <>

            
            <h1 className="text-sm text-white text-center mt-4">Kiitos!</h1>
            <h1 className="text-sm text-white text-center mt-4">Ennakkovarauksesi on nyt poistettu.</h1>    
            <h1 className="text-sm text-white text-center mt-4">Mikäli haluat katsoa muita vapaita myyntipaikkoja niin valitse valikosta Myyntipaikat - lista tai -kartta.</h1>            
        </>
            
        : 

        /* Actual preorder part */
        <>
        <h1 className="text-sm text-white text-center mt-4">1. Peruuta varaus paikoilta, joita et tarvitse.</h1>
        <h1 className="text-sm text-white text-center mt-2">2. Tarkasta ja tarvittaessa muuta paikan sähkövalintaa klikkaamalla Ei sähköä, Valovirta tai Voimavirta ruutuja.</h1>
        <h1 className="text-sm text-white text-center mt-4">3. Vahvista lopuksi paikkavaraus Vahvista varaus painikkeella</h1>

        <h1 className="text-sm text-white text-center mt-2"><p>Kirjautumalla palveluun voit myös muokata myyjätietojasi ja lisätä julkisessa myyjälistauksessa näytettäviä tietoja kuten yhteystiedot, kotisivut, fb, instagram sekä kuvailla tarjoamisia palveluita.</p></h1>

        <div className={`mt-4 gap-2 grid sm:w-full  ${
      filteredSpots.length == 1 ? "grid-cols-1" : filteredSpots.length == 2 ? "grid-cols-1 sm:grid-cols-2 "
      : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
      }`}>
        {loaded && filteredSpots.map(spot => {
            return <SpotCard spot={spot} key={spot.id} preorder={true}/>
        })}
        </div>

        <div className='p-4 m-auto mt-2 flex flex-col justify-between items-center'>
            <div className='flex flex-col sm:flex-row sm:space-x-4'>
            </div>
            <div className='flex flex-col sm:flex-row w-full sm:w-auto sm:space-x-2 mt-2 sm:mt-0'>                
                <Button name="Vahvista varaus" type="PRIMARY" action={() => comfirm()} />                
            </div>
        
        </div>
        </>
        }
        </>}</>}
    </div>

  )
}
