import {useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate, useLocation} from 'react-router-dom';
import useAxios from '../api/axios';
import { toast } from 'react-toastify';


const Register = () => {    

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    
    //const errRef = useRef();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const api = useAxios();

    function validateForm() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let ok = true;

        
        if (!emailRegex.test(email)) {
            toast.error("Tarkista sähköposti osoite");
            ok = false;
        } 

        if(name.trim().length === 0) {
            toast.error("Syötä nimesi")
            ok = false;
        }
        
        if(phone.trim().length === 0) {
            toast.error("Anna puhelinumerosi")
            ok = false;
        }

        if(password.length < 8) {
            toast.error("Salasanan tulee olla vähintään 8 merkkiä");
            ok = false;
        }

        return ok;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();    
        
        if(!validateForm()) {
            return;
        }

        try {
            const response = await api.post('/login?action=register',
                JSON.stringify({ name, phone, email, password }),
                {
                    headers: { 'Content-Type': 'application/json'},                    
                }
            );
            
            
            navigate(from, {replace: true});
            toast.info("Rekisteröinti onnistui");
        } catch(err) {
            if(!err?.response) {
                toast.error("Ei vastausta palvelimelta");
                //setErrMsg("Ei vastausta palvelimelta");
            } else if(err.response?.status === 400) {
                toast.error("Palvelin virhe");
                //setErrMsg("Palvelin virhe");
            } else if(err.response?.status === 401) {
                toast.error("Tunnukset eivät kelpaa");
                //setErrMsg("Tunnukset eivät kelpaa");
            } else {
                toast.error("Rekisteröinti epäonnistui");
                //setErrMsg("Kirjautuminen epäonnistui");
            }
            //errRef.current.focus();
        }        
    }    

    

  return (
    <div className="max-w-[800px] mt-[96px] w-full h-full mx-auto text-center flex  flex-col">                
        <h1 className="text-4xl pb-6 mb-4 font-bold text-blue-800">Rekisteröidy</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
        <Stack className="mb-2" direction="column" alignItems="center" justifyContent="center" spacing={2}>
        <TextField
                  variant="filled"
                  margin="normal"
                  required                     
                  id="name"
                  label="Nimi"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  sx={{
                    width: 5/12,
                    backgroundColor: "#f0f0f0", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  variant="filled"
                  margin="normal"
                  required                  
                  id="phone"
                  label="Puhelin"
                  name="phone"
                  autoComplete="phonenumber"    
                  sx={{
                    width: 5/12,
                    backgroundColor: "#f0f0f0", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}               
                  onChange={e => setPhone(e.target.value)}
                />
                <TextField
                  variant="filled"
                  margin="normal"
                  required                  
                  id="email"
                  label="Sähköposti"
                  name="email"
                  autoComplete="off"   
                  sx={{
                    width: 5/12,
                    backgroundColor: "#f0f0f0", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}               
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  variant="filled"
                  margin="normal"
                  required                  
                  name="password"
                  label="Salasana"
                  type="password"                  
                  id="password"
                  autoComplete="new-password"
                  sx={{
                    width: 5/12,
                    backgroundColor: "#f0f0f0", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setPassword(e.target.value)}
                />
            </Stack>

            <a target="_blank" href='https://www.lcmynamaki.fi/wp-content/uploads/LaurinMarkkinat/Laurin-markkinat-tietosuojaseloste_v10032025.pdf'>Tietoturvaseloste</a>
            
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{p: 2}} spacing={2}>
                <Button type="submit" variant="contained" color="primary">Rekisteröidy</Button>                
                <Button variant="contained"  color="secondary" href="/">Takaisin</Button>
            </Stack>
            
        </form>
    </div>
  )
}

export default Register