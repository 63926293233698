import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useSpotContext } from '../context/SpotProvider';
import { Button } from './Button';
import { FaMap } from 'react-icons/fa';
import { TfiViewListAlt } from "react-icons/tfi";
import useAuth from '../hooks/useAuth';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import { TbArrowBigRightLines } from "react-icons/tb";
import { PiArrowFatLineRight } from "react-icons/pi";
import { PiArrowFatLineLeft } from "react-icons/pi";


const SPOT_STATUS_LOCKED = process.env.REACT_APP_SPOT_STATUS_LOCKED;
const SPOT_STATUS_FREE = process.env.REACT_APP_SPOT_STATUS_FREE;
const SPOT_STATUS_PREORDERED = process.env.REACT_APP_SPOT_STATUS_PREORDERED;
const SPOT_STATUS_ORDERED = process.env.REACT_APP_SPOT_STATUS_ORDERED;
const SPOT_STATUS_INVOICED = process.env.REACT_APP_SPOT_STATUS_INVOICED;
const SPOT_STATUS_PAYED = process.env.REACT_APP_SPOT_STATUS_PAYED;
const LOCK_TIMEOUT = process.env.REACT_APP_SPOT_LOCK_TIMEOUT_SEC;

//export const SpotMenu = ({count_kaikki, count_vapaa, count_valittu, count_omat, time, showSpots, setShowSpots}) => {                                          
//

export const SpotMenu = ({showSpots, setShowSpots, sizeFilter, setSizeFilter, isMapView=false}) => {   
    //const [showSpots, setShowSpots] = useState('Kaikki');


    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [totalSpotPrice, setTotalSpotPrice] = useState(0);
    const [totalElectricityPrice, setTotalElectricityPrice] = useState(0);
    const [sizes, setSizes] = useState(null);
    const {spots, unlockSpot} = useSpotContext();                                       
    const navigate = useNavigate();    
    const {auth} = useAuth();

    const count_kaikki = useMemo(() => spots?.length, [spots]);
    //const count_omat = 0;
    //const count_valittu = 0;
    //const count_vapaa = 0;
    //const time = 0;
    
      const count_vapaa = spots.filter(spot => spot.status === SPOT_STATUS_FREE || spot.status === SPOT_STATUS_LOCKED).length;
      const count_varattu = spots.filter(spot => spot.status === SPOT_STATUS_ORDERED || spot.status === SPOT_STATUS_PAYED || spot.status === SPOT_STATUS_INVOICED || spot.status === "Maksu paikalla").length;
      let count_valittu = 0;
      let count_omat = 0;
      let time = -1;                     

     if(auth.user) {
        const valitut = spots.filter(spot => auth.user?.userID === spot.userID && ((spot.status === SPOT_STATUS_LOCKED) || (spot.status === SPOT_STATUS_PREORDERED)));
        count_valittu = valitut.length;
        const omat = spots.filter(spot => spot.userID === auth.user?.userID);
        count_omat = omat.length;
    
        if(count_valittu > 0) {
          const mylocked = spots.filter(spot => auth.user?.userID === spot.userID && (spot.status === SPOT_STATUS_LOCKED));
          if(mylocked.length > 0) {
            mylocked.forEach(spot => {
              if(spot.locked === null && time === -1) {              
                time = Date.now();              
              }
              else if(time === -1 || new Date(spot.locked).getTime() > time) {
                time = new Date(spot.locked).getTime();              
              }
            });
            time = time + LOCK_TIMEOUT*1000;            
          }


           

        } 
      }

      if(!sizes && spots && spots.length > 0) {
        const sizeCounts = {};
        
        spots.forEach(spot => {
          if (sizeCounts[spot.size]) {
            sizeCounts[spot.size]++;
          } else {
            sizeCounts[spot.size] = 1;
          } 
        })

        const sortedSizes = Object.keys(sizeCounts).sort();

        // Create an ordered list of sizes and their counts
        const orderedSizeCounts = sortedSizes.map(size => ({
          size,
          count: sizeCounts[size]
        }));

        setSizes(orderedSizeCounts);
      }
    
      console.log(new Date());

      /*
      if(count_valittu === 0 && (showSpots === "Valitut" || showSpots === "Omat")) {
        setShowSpots("Vapaat");
      }
        */

    useEffect(() => {

      if(auth?.user && spots) {
        const valitut = spots.filter(spot => auth.user?.userID === spot.userID && ((spot.status === SPOT_STATUS_LOCKED) || (spot.status === SPOT_STATUS_PREORDERED)));
        let total = valitut.reduce((total, spot) => (total) + parseInt(spot.fixedPrice ?? spot.prize, 10), 0);

        total = total + valitut.reduce((total, spot) => 
              total + (spot.fixedPrice ? 0 : (spot.electricityType === "230V" || spot.electricityType === "400V") ? 15 : 0),0);

        setTotalSpotPrice(total);
      }
        
    }, [count_valittu, spots]); 
      

    useEffect(() => {
        if((count_valittu === 0 && showSpots === "Valitut") || (count_omat === 0 && showSpots === "Omat")) {
            setShowSpots("Vapaat");
        }
    }, [count_valittu, count_omat, showSpots, setShowSpots]);  

    const gotoPage = useCallback((linkName) => {
        navigate(linkName);
    },[])

    const handleChange = (event) => {
        setShowSpots(event.target.value);
        
        if(count_valittu === 0 && event.target.value === "Valitut") {
          toast.warn("Et ole valinnut vielä yhtään paikkaa.");
        }
    };

    const unlockAll = () => {
      console.log("SpotMenu - unlockAll");
      spots.filter(spot => spot.status === SPOT_STATUS_LOCKED && spot.userID === auth.user.userID).forEach(spot => {
        unlockSpot(spot,SPOT_STATUS_FREE);
      }) 
    }

      // Handle checkbox change
  const handleCheckboxChange = (size) => {
    setSizeFilter((prevSizeFilter) => {
      if (prevSizeFilter && prevSizeFilter.includes(size)) {
        // Remove size from selected if already selected
        return prevSizeFilter.filter((item) => item !== size);
      } else {
        // Add size to selected
        return [...prevSizeFilter, size];
      }
    });
  };
           

  return (
    <>

      {showFilterMenu ?
        (<>
        <div className='fixed right-0 top-32 flex flex-col px-2 py-2 rounded-l-xl bg-blue-500 text-white z-30'>  
          
            <div onClick={() => setShowFilterMenu(false)} className="flex flex-row cursor-pointer justify-center ml-1 text-sm hover:text-gray-700"><PiArrowFatLineRight className="mb-2 mr-1 text-sm ml-0 px-0 mt-1"/>Sulje</div> 
            <div>
            <FormControl>      
              <span className="text-sm font-semibold text-white mb-1 block">Tila</span>
              <RadioGroup
                column
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={showSpots}
                onChange={handleChange}
                className="space-y-0" 
                
              >
                <FormControlLabel key="c1" value="Kaikki" control={<Radio sx={{ transform: "scale(0.6)", height: 24, color: "white", '&.Mui-checked': { color: "white" } }} />} label={<span className="text-xs">Kaikki ({count_kaikki})</span>} className="py-0 mt-0 mb-0" />
                <FormControlLabel key="c2" value="Varatut" control={<Radio sx={{ transform: "scale(0.6)", height: 24, color: "white", '&.Mui-checked': { color: "white" } }} />} label={<span className="text-xs">Varatut ({count_varattu})</span>} className="mb-0" />
                <FormControlLabel key="c3" value="Vapaat" control={<Radio sx={{ transform: "scale(0.6)", height: 24, color: "white", '&.Mui-checked': { color: "white" } }} />} label={<span className="text-xs">Vapaat ({count_vapaa})</span>} className="mb-0" />
                {count_valittu > 0 ?
                <FormControlLabel key="c4" value="Valitut" control={<Radio sx={{ transform: "scale(0.6)", height: 24, color: "white", '&.Mui-checked': { color: "white" } }} />} label={<span className="text-xs">Vahvistamattomat ({count_valittu})</span>}/>
                : null }
                {count_omat > 0? 
                <FormControlLabel key="c5" value="Omat" control={<Radio sx={{ transform: "scale(0.6)", height: 24, color: "white", '&.Mui-checked': { color: "white" } }} />} label={<span className="text-xs">Omat ({count_omat})</span>}/>
                : null
                }
              </RadioGroup>
            </FormControl>  

            <FormControl> 
              <span className="text-sm font-semibold text-white mt-2 block">Koko</span>  
              <div className="flex flex-col space-y-0"> {/* Adjust spacing */}

              { sizes && sizes.map((size,index) => {
                  return <FormControlLabel 
                  key={index} 
                  control={<Checkbox 
                    checked={sizeFilter && sizeFilter.includes(size)}
                    onChange={() => handleCheckboxChange(size)}
                    sx={{ 
                    transform: "scale(0.6)", 
                    color: "white",
                    height: 24,
                    '&.Mui-checked': { color: "white" }  
                  }} />} 
                  label={<span className="text-xs text-white">{size.size} ({size.count})</span>}
                  sx={{ marginBottom: 0 }}
                />  
              })}  
                  
              </div>
            </FormControl>
          </div>
        </div></>)
      : <div onClick={() => setShowFilterMenu(true)} className="flex flex-row fixed right-0 top-32 cursor-pointer bg-blue-500 text-white text-sm px-0 py-3 rounded-l-lg hover:bg-blue-700 [writing-mode:vertical-rl] z-30">
        <span><PiArrowFatLineLeft className="mb-2 mr-1 text-sm ml-0 px-0"/></span> 
        <span>Suodata</span></div>

      
      }
    
    
    <div className='fixed top-16 w-full shadow bg-gray-400 left-1/2 -translate-x-1/2 pt-1 pb-2 pr-4 pl-4 mb-4 flex flex-row sm:flex-row justify-between items-start sm:items-center z-30'>
      {auth?.user &&
        <div className='flex flex-col items-center space-y-0 leading-tight text-lg text-blue-800 max-sm:text-sm'>          
          <span>Yhteishinta</span><span>{totalSpotPrice} €</span>          
        </div>
      }
      {/*
      <div className='flex flex-col max-md:hidden'>   

    <FormControl>      
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={showSpots}
        onChange={handleChange}
      >
        <FormControlLabel key="c1" value="Kaikki" control={<Radio sx={{ transform: "scale(0.8)" }} />} label={<span className="text-sm">Kaikki ({count_kaikki})</span>}/>
        <FormControlLabel key="c2" value="Vapaat" control={<Radio sx={{ transform: "scale(0.8)" }} />} label={<span className="text-sm">Vapaat ({count_vapaa})</span>}/>
        {count_valittu > 0 ?
        <FormControlLabel key="c3" value="Valitut" control={<Radio sx={{ transform: "scale(0.8)" }} />} label={<span className="text-sm">Vahvistamattomat ({count_valittu})</span>}/>
        : null }
        {count_omat > 0? 
        <FormControlLabel key="c4" value="Omat" control={<Radio sx={{ transform: "scale(0.8)" }} />} label={<span className="text-sm">Omat ({count_omat})</span>}/>
        : null
        }
      </RadioGroup>
      
    </FormControl>
    </div>
    */}
    
    <div className='flex flex-row max-sm:flex-row max-sm: items-center m-auto mr-0 max-sm:w-auto space-x-2 mt-2'>
    { isMapView ?     
    <Button name="Lista" icon={<TfiViewListAlt className="inline mr-1"/>} type="PRIMARY" action={() => gotoPage("/myyntipaikat-lista")} />
    : <Button name="Kartta" icon={<FaMap/>} type="PRIMARY" action={() => gotoPage("/myyntipaikat-kartta")} />
    }
    {auth.user && count_valittu > 0 ? (
        !time || time === -1 ? (
          <Button name="Vahvista..." type="PRIMARY" action={() => gotoPage("/vahvista-sahko")} />
        ) : (
          <Button
            name="Vahvista..."
            timer={time}
            timerCallback={() => unlockAll()}
            type="PRIMARY"
            action={() => gotoPage("/vahvista-sahko")}
          />
        )
      ) : (
        <Button name="Vahvista..." type="DISABLED" />
      )}
      </div>
    
    </div>
    </>
  )
}


SpotMenu.whyDidYouRender = true;