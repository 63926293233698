import {useState, useRef, useEffect} from 'react'
import useAuth from '../../hooks/useAuth';
import useAxios from '../../api/axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';

const ADMIN_LOGIN_URL = 'loginAdmin.php';
const LOGIN_URL = "login?action=login";

const Login = () => {
    const {setAuth} = useAuth();

    const api = useAxios();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const errRef = useRef();

    const [username, setUsername] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');    



    useEffect(() => {

        setErrMsg('');
    },[username,pwd]);

    const adminLogin = async () => {        

        try {
            const response = await api.post(ADMIN_LOGIN_URL,
                JSON.stringify({username: username, password: pwd}),
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials : true
                }
            );
            const accessToken = response?.data?.accessToken;
            const user = {name: response.data.name, isAdmin: response.data.isAdmin};
            //const ID = response?.data?.ID;
            //const name = response?.data?.name;
            //const isAdmin = response?.data?.isAdmin;
            //JSON.stringify(response?.data?.accessToken);
            //alert(response?.data?.accessToken);
            //setAuth( {user, accessToken, ID, name, isAdmin });
            setAuth( {user, accessToken});
            setUsername('');
            setPwd('');
            navigate(from, {replace: true});
        } catch(err) {
            if(!err?.response) {
                toast.error("Ei vastausta palvelimelta");
                //setErrMsg("Ei vastausta palvelimelta");
            } else if(err.response?.status === 400) {
                toast.error("Palvelin virhe");
                //setErrMsg("Palvelin virhe");
            } else if(err.response?.status === 401) {
                toast.error("Tunnukset eivät kelpaa");
                //setErrMsg("Tunnukset eivät kelpaa");
            } else {
                toast.error("Kirjautuminen epäonnistui");
                //setErrMsg("Kirjautuminen epäonnistui");
            }
            //errRef.current.focus();
        }        
    }    

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            
            const response = await api.post(LOGIN_URL,
                JSON.stringify({action: "login", email: username, password: pwd}),
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials : true
                }
            );
            const accessToken = response?.data?.token;
            const user = response?.data?.user;
            //const ID = response?.data?.ID;
            //const name = response?.data?.name;
            //const isAdmin = response?.data?.isAdmin;
            //JSON.stringify(response?.data?.accessToken);
            //alert(response?.data?.accessToken);
            setAuth( {user, accessToken});
            setUsername('');
            setPwd('');
            toast.info("Kirjautuminen onnistui.")
            navigate(from, {replace: true});
        } catch(err) {
            if(!err?.response) {
                toast.error("Ei vastausta palvelimelta");
                //setErrMsg("Ei vastausta palvelimelta");
            } else if(err.response?.status === 400) {
                toast.error("Palvelin virhe");
                //setErrMsg("Palvelin virhe");
            } else if(err.response?.status === 401) {
                toast.error("Tunnukset eivät kelpaa");
                //setErrMsg("Tunnukset eivät kelpaa");
            } else {
                toast.error("Kirjautuminen epäonnistui");
                //setErrMsg("Kirjautuminen epäonnistui");
            }
            errRef.current.focus();
        }        
    }

  return (
    <div className="max-w-[800px] mt-[96px] w-full h-full mx-auto text-center flex  flex-col">        
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <h1 className="text-4xl pb-6 mb-4 font-bold text-blue-800">Kirjaudu</h1>
        <form onSubmit={handleSubmit}>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <TextField
                variant="filled"
                margin="normal"
                label="Käyttäjätunnus"                
                value={username}
                sx={{
                    width: 5/12,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}   
                onChange={(e) => setUsername(e.target.value)}                
            />            
            <TextField
                variant="filled"
                margin="normal"
                label="Salasana"                
                type="password"                                           
                value={pwd}                
                sx={{
                    width: 5/12,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}   
                onChange={(e) => setPwd(e.target.value)}                
            />
            </Stack>
     
            <div className="text-center text-white text-sm"><Link to="/uusi-salasana">Unohtuiko salasana?</Link></div>


            <Stack direction="row" alignItems="center" justifyContent="center" sx={{p: 2}} spacing={2}>
                <Button type="submit" variant="contained" color="primary">Kirjaudu</Button>                
                <Button variant="contained"  color="secondary" href="/">Takaisin</Button>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2 }} spacing={2}>
                <Button variant="contained" color="primary" onClick={() => adminLogin()}>Kirjaudu ylläpitäjänä</Button>
            </Stack>
            
        </form>
    </div>
  )
}

export default Login