export default(data, action) => {
    switch(action.type) {
        case "UPDATE_STATUS":
            //console.log("My data " + JSON.stringify(data));
            const aSpot = data.spots.find(spot => spot.spotID == action.payload.spot.spotID);
            //console.log("Check types: " + typeof aSpot.spotID, typeof action.payload.spot.spotID);
            const newData = {...data, spots: data.spots.map(spot => spot.spotID === action.payload.spot.spotID ? { ...spot, userID: action.payload.userID, status: action.payload.newStatus, locked: action.payload.locked} : spot)};
            return newData;
    //    case "SET":
    //        return {...data, spots: action.payload};
    case "SET_ELECTRICITY":
        return {...data, spots: data.spots.map(spot => spot.spotID === action.payload.spotID ? { ...spot, electricityType: action.payload.electricityType} : spot)};
    case "SET":
        return {
            ...data,
            spots: action.payload.map((spot, index) => {
            const oldSpot = data.spots[index] || {}; // Get new spot data or default to an empty object
            const hasChanged = oldSpot.electricityType !== spot.electricityType;
            const elec = oldSpot.status === 'Lukittu' || oldSpot.status === 'Ennakkovaraus' || oldSpot.status === 'Varattu' ? oldSpot.electricityType : spot.electricityType;

            return {
                ...spot,        // Keep all existing properties
             //   ...oldSpot,     // Overwrite with new data
                electricityType: elec, // Preserve this property
                electrivityChanged: hasChanged
            };
            }),
        };
    case "JUST_ORDERED":
        return {...data, justOrdered: action.payload.justOrdered};
    default: 
        return data;
    }
}