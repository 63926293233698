import React,{useState, useEffect} from 'react'
import { useSpotContext } from '../context/SpotProvider';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const bgtablebody = "bg-blue-300 text-gray-700";

export const ComfirmOrder3 = () => {
  const { getJustOrdered } = useSpotContext();
  const [spots, setSpots] = useState([]);
  //const [compSelected,setCompSelected] = useState(false);

  let total = 0;
  total = spots.reduce((tot, spot) => 
    tot + spot.fixedPrice ? parseInt(spot.fixedPrice) : (parseInt(spot.prize) + ((spot.electricityType === "230V" || spot.electricityType === "400V") ? 15 : 0)),0);

 

  const navigate = useNavigate();
  
  useEffect(() => {
    if(spots.length === 0) {
      let s = getJustOrdered();
      s = s ? s : [];
      setSpots(s);      
    }

    

  },[]);


  const goBack = () => {
    navigate(-1);
  }

  return (
    <div>

      
      <h1 className="text-2xl text-blue-800 text-center mt-4">Kiitos varauksestasi!</h1>      
      
      <h1 className="text-sm text-white text-center mt-4">Valitsemasi paikat on nyt varattu sinulle.</h1>      

      <div class="flex mt-4 justify-center items-center">
        <div class="w-full sm:w-3/4 md:w-1/2 lg:w-3/7 mx-2 grid grid-cols-4 border border-gray-300 items-center text-center">
        
          <div class="bg-gray-200 p-1 font-bold border border-gray-400">Tuote</div>
          <div class="bg-gray-200 p-1 font-bold border border-gray-400">Paikka</div>
          <div class="bg-gray-200 p-1 font-bold border border-gray-400">Tyyppi</div>
          <div class="bg-gray-200 p-1 font-bold border border-gray-400">Hinta</div>      

          {spots.map((spot,index) => (                            
              <>
                <div class={`p-1 ${bgtablebody} border border-gray-400`}>Paikkamaksu</div>
                <div class={`p-1 ${bgtablebody} border border-gray-400`}>{spot.name}</div>
                <div class={`p-1 ${bgtablebody} border border-gray-400`}>{spot.size}</div>
                <div class={`p-1 ${bgtablebody} border border-gray-400`}>{spot.fixedPrice ?? spot.prize} €</div>
                                
                {spot.electricityType !== "Ei sähköä" && (
                  <>
                    <div class={`p-1 ${bgtablebody} border border-gray-400`}>Sähkömaksu</div>
                    <div class={`p-1 ${bgtablebody} border border-gray-400`}>{spot.name}</div>
                    <div class={`p-1 ${bgtablebody} border border-gray-400`}>{spot.electricityType}</div>
                    <div class={`p-1 ${bgtablebody} border border-gray-400`}>{spot.fixedPrice ? 0 : 15} €</div>
                  </>
                )}    
              </>         
          ))}  

            <div class="col-span-3 bg-blue-500 p-1 font-bold border border-gray-400 text-right pr-4">Yhteensä</div>
            <div class={`p-1 bg-blue-500 border font-bold border-gray-400`}>{total} €</div>
        </div>
        
      </div>
      <h1 className="text-sm text-white text-center mt-4">Lähetämme laskun sähköpostilla laskutusosoitteeseesi.</h1>
      <h1 className="text-sm text-white text-center mt-4">Muista maksaa lasku eräpäivään mennessä pitääksesi varauksesi voimassa</h1>
      <h1 className="text-md text-white text-center mt-4">Kiitos ja nähdään markkinoilla elokuussa.</h1>
    </div>
  )
}
