import React from 'react'
import {Timer} from './Timer';
import { MdCancel } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

export const Button = ({name, bgcolor, bghover, type, action, icon, timer, timerCallback}) => {

    let cursor = "cursor-pointer";

    if(type) {
        switch (type.toUpperCase()) {
            case "PRIMARY":
                bgcolor = "bg-blue-500";
                bghover = "bg-blue-700";
                break;
            case "CANCEL":
                bgcolor = "bg-yellow-500";
                bghover = "bg-yellow-800";
                icon=<MdCancel />
                break;
            case "DELETE":
                bgcolor = "bg-red-500";
                bghover = "bg-red-700";
                icon=<FaRegTrashAlt  />
                break;    
            case "EDIT":
                bgcolor = "bg-yellow-500";
                bghover = "bg-yellow-800";
                icon=<CiEdit  />
                break;      
            case "SECONDARY":
                bgcolor = "bg-yellow-500";
                bghover = "bg-yellow-800";
                break;
            case "DISABLED":
                bgcolor = "bg-gray-500";
                bghover = "bg-gray-800";
                cursor = "";
                break;    
            default:
                if(!bgcolor) {
                    bgcolor = "bg-blue-500";
                    bghover = "bg-blue-700";
                }
        }
    }

    //<div className={`${bgcolor} hover:${bghover} text-black px-4 ${cursor} py-2 rounded mb-2 sm:mb-0 w-full sm:w-auto text-center flex items-center justify-center gap-2`} onClick={action} variant="contained" color="primary">{icon} {name}

  return (
        <div className={`${bgcolor} hover:${bghover} text-white px-4 ${cursor} py-1 rounded mb-1 text-center flex items-center justify-center gap-2`} onClick={action} variant="contained" color="primary">{icon} {name}
        {timer ? <Timer startTime={timer} timeoutCallBack={timerCallback}/> : null}
        </div>    
  )
}
