import React, {useState} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import useAxios from '../../api/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const LOGIN_URL = "login?action=renew";


export const RenewPWD = () => {
  const [username, setUsername] = useState('');

  const api = useAxios();
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
          e.preventDefault();
  
          try {
  
              
              const response = await api.post(LOGIN_URL,
                  JSON.stringify({action: "renew", email: username}),
                  {
                      headers: { 'Content-Type': 'application/json'}                      
                  }
              );
              toast.info("Uusi salasana on lähetetty sähköpostiisi.")
              navigate("/login", {replace: true});
          } catch(err) {
              if(!err?.response) {
                  toast.error("Ei vastausta palvelimelta");
                  //setErrMsg("Ei vastausta palvelimelta");
              } else if(err.response?.status === 400) {
                  toast.error("Palvelin virhe");
                  //setErrMsg("Palvelin virhe");
              } else if(err.response?.status === 401) {
                  toast.error("Tunnukset eivät kelpaa");
                  //setErrMsg("Tunnukset eivät kelpaa");
              } else {
                  toast.error("Kirjautuminen epäonnistui");
                  //setErrMsg("Kirjautuminen epäonnistui");
              }              
          }        
      }



  return (
    <div className="max-w-[800px] mt-[96px] w-full h-full mx-auto text-center flex  flex-col">                
        <h1 className="text-4xl mb-4 font-bold text-blue-800">Salasana unohtunut?</h1>
        <h1 className="text-sm font-bold text-blue-800">Anna sähköpostiosoitteesi niin lähetämme uuden salasanasi</h1>
        <h1 className="text-sm pb-4 mb-4 font-bold text-blue-800">siihen mikäli olet rekisteröinyt antamallasi sähköpostilla.</h1>
        <form onSubmit={handleSubmit}>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <TextField
                variant="filled"
                margin="normal"
                label="Sähköposti"                
                value={username}
                sx={{
                    width: 5/12,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}   
                onChange={(e) => setUsername(e.target.value)}                
            />                        
            </Stack>
                 
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{p: 2}} spacing={2}>
                <Button type="submit" variant="contained" color="primary">Lähetä</Button>                
                <Button variant="contained"  color="secondary" href="/">Takaisin</Button>
            </Stack>            
            
        </form>
    </div>
  )
}
