import { useState } from "react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, confirmButtonTxt="Poista", msg }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center">
        <h2 className="text-lg font-semibold">Vahvista toiminto</h2>
        <p className="text-gray-600 my-4 whitespace-pre-line">{msg}</p>
        <div className="flex justify-around">
          <button
            className="bg-gray-400 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Peruuta
          </button>
          <button
            className={`${confirmButtonTxt==="Poista" ? "bg-red-500" : "bg-green-500"} text-white px-4 py-2 rounded`}
            onClick={onConfirm}
          >
            {confirmButtonTxt}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;