import axios from 'axios';
import { useAuth } from '../context/AuthProvider';

//const BASE_URL = 'https://vuokraa.lcmynamaki.fi';
const BASE_URL = 'https://lauri.lcmynamaki.fi';

/*
const auth = () => { 
        let data = localStorage.getItem('userData'); 
        return data ? JSON.parse(data) : {};
    
    };
*/

const useAxios = () => {

const { auth,setAuth} = useAuth();    

const api = axios.create({
    baseURL: BASE_URL,    
});


// Flag to prevent multiple refresh requests
let isRefreshing = false;
let refreshSubscribers = [];

// Function to refresh the token
const refreshAccessToken = async () => {
  try {    
    const response = await api.post("/refresh", {},{withCredentials: true,});

    return response.data.token;
  } catch (error) {
    console.error("Refresh Token Failed", error);
    window.location.href = "/login"; // Redirect to login page
    return null;
  }
};

// Add interceptor to modify requests
api.interceptors.request.use(config => {

    if(config.method === 'post') {
                
        const token = auth?.accessToken || null;
            
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
        config.headers['Referrer-Policy'] = 'no-referrer';
    
        // If token, add to headers
        if (token) {
            config.headers['x-auth-token'] = token;
        }   
    }
  
    return config;   
}, error => Promise.reject(error));


api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      // If the response is 401 (Unauthorized), try to refresh the token
      if (error.response?.status === 401 && !originalRequest._retry && !originalRequest.url.startsWith('login')) {        
        if (isRefreshing) {
          return new Promise((resolve) => {
            refreshSubscribers.push((token) => {
              originalRequest.headers['x-auth-token'] = `${token}`;
              resolve(api(originalRequest));
            });
          });
        }
  
        originalRequest._retry = true;
        isRefreshing = true;
  
        const newAccessToken = await refreshAccessToken();
        isRefreshing = false;
  
        if (newAccessToken) {

          console.log("Accesstoken on uusittu");
          setAuth(prev => {
            //console.log(JSON.stringify(prev));
            //console.log(response.data.accessToken);
            return { ...prev,accessToken: newAccessToken}
          });

          refreshSubscribers.forEach((callback) => callback(newAccessToken));
          refreshSubscribers = [];
  
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
      }
  
      return Promise.reject(error);
    }
  );

  return api;
}

export default useAxios;

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json'},
    withCredentials: true    
});