import { Outlet } from 'react-router-dom';



function AppHeader () {
    return ( 
        <header>
            <Outlet/>
        </header>            
    )
}

export default AppHeader;