
import React, {useState} from 'react';

import { useSpotContext } from '../context/SpotProvider';
import { Button }  from './Button';
import { useAuth } from '../context/AuthProvider';
import { FaLock, FaLockOpen } from "react-icons/fa";
import { FcElectricity } from "react-icons/fc";
import { BiSolidMinusCircle } from "react-icons/bi";
import ConfirmationModal from './ConfirmationModal';

const SPOT_STATUS_LOCKED = process.env.REACT_APP_SPOT_STATUS_LOCKED;
const SPOT_STATUS_FREE = process.env.REACT_APP_SPOT_STATUS_FREE;
const SPOT_STATUS_PREORDERED = process.env.REACT_APP_SPOT_STATUS_PREORDERED;
const SPOT_STATUS_ORDERED = process.env.REACT_APP_SPOT_STATUS_ORDERED;
const SPOT_STATUS_INVOICED = process.env.REACT_APP_SPOT_STATUS_INVOICED;
const SPOT_STATUS_PAYED = process.env.REACT_APP_SPOT_STATUS_PAYED;

const SpotCard = ({ spot, preorder = false }) => {
    
    const [isModalOpen, setModalOpen] = useState(false);
    const [isElectricModalOpen, setElectricModalOpen] = useState(false);
    const [electriModalMessage, setElectriModalMessage] = useState("");
    const [electricModalElectricType, setElectricModalElectricType] = useState("");
    const {lockSpot, unlockSpot,setElectricity} = useSpotContext();
   

    const { isAuthenticated, isAdmin, auth } = useAuth();

    let bgBorder = "border-white";

    if(spot.status === SPOT_STATUS_FREE) {
      bgBorder = "border-free"; 
    } else if(preorder || spot?.userID === auth?.user?.userID) {
      bgBorder = "border-locked";
    } else if(spot.status === SPOT_STATUS_PREORDERED) {
      bgBorder = "border-preorder"; 
    } else {
      bgBorder = "border-ordered";
    }

    

    const changeStatus = (spot,status) => {
      //lockSpot(spot,status);        
    }

    const lock = (spot,status) => {
        lockSpot(spot,auth.user?.userID,status);        
        //dispatch({ type: "UPDATE_STATUS", payload: {spot: spot, newStatus: status} });
    }

    const removeOrder = () => {
      setModalOpen(false);
      console.log("removeOrder");            
      unlockSpot(spot,"Vapaa");                        
    }

    const unlock = (spot,status) => {
      console.log("SpotCard - unlock");

      if(spot.status === 'Ennakkovaraus') {
        setModalOpen(true);
      } else if(spot.status === 'Varattu') {
        setModalOpen(true);
      } else if(spot.status === 'Lukittu') {
        unlockSpot(spot,status);        
      } 
      
  }

  const changeElectricType = async () => {
    setElectricity(spot.spotID, electricModalElectricType, true);
    setElectricModalOpen(false);
  }

  const select = (elec) => {
    if(spot.status === SPOT_STATUS_ORDERED) {
      setElectricModalElectricType(elec);
      setElectriModalMessage(`Haluatko vaihtaa jo varatun paikan ${spot.name} sähkötyyppiä\n"${spot.electricityType}" => "${elec}"?`)
      setElectricModalOpen(true);
    } else if(spot.status === SPOT_STATUS_INVOICED) {

    } else {    
      setElectricity(spot.spotID, elec);
    }
    //spot.electricity200 = elec;    
  }

  const getStatusText = (spot) => {
    if(auth.user?.userID === spot.userID) {
      return spot.status;
    } else {
      switch(spot.status) {
        case "Laskutettu":
        case "Varattu":
        case "Maksettu":
        case "Maksu paikalla":
          return "Varattu";
        default:
          return spot.status;
      }
      return "höpöhöpö";
    }
  }

  return (
    <div className={`bg-white shadow-lg rounded-xl p-2 m-2  mx-auto border-4 ${bgBorder}`}>
      {/* Grid for values */}

      {spot.status !== SPOT_STATUS_FREE && spot.status !== SPOT_STATUS_LOCKED && 
      <div className="flex flex-col items-center bg-gray-300 p-1 mb-1 rounded-lg w-full"><span>{spot.company}</span></div>
      }

      <div className={`grid grid-cols-2 sm:grid-cols-4 gap-2`}>
        {[
          { title: "Paikka", value: spot.name, bgColor: "bg-blue-500",width: "min-w-[10px]" },
          { title: "Koko", value:  spot.size, bgColor: "bg-black",width: "min-w-[10px]" },
          (preorder || spot.status === SPOT_STATUS_FREE || (auth.user?.userID === spot?.userID && spot.status !== SPOT_STATUS_FREE) ? 
          { title: "Hinta", value: (spot.fixedPrice ? spot.fixedPrice : spot.prize) + " €",width: "min-w-[10px]" } :
          { title: "Sähkö", value: spot.electricityType, width: "min-w-[10px]" }),
          { title: "Tila", value: (getStatusText(spot)),width: "min-w-[10px]" },
        ].map((item, index) => (
          <div
            key={index}
            className={`flex flex-col items-center bg-gray-300 p-3 rounded-lg w-full ${item.width}`}
          >
            <span className="text-xs text-gray-500">{item.title}</span>
            <span className={`${item.value.length > 8 ? "text-xs" : "text-lg"}  font-semibold text-center w-full truncate`}>{item.value}</span>
          </div>
        ))}
      </div>

      { (preorder || (auth.user?.userID === spot?.userID && spot.status !== SPOT_STATUS_FREE)) && (
      <div className={`grid grid-cols-2 sm:grid-cols-4 gap-2 mt-2`}>
              {[
                { 
                  title: "Sähköpaikka",  
                  title2: "Lisämaksu",                 
                  value: (spot.fixedPrice || spot.electricityType === 'Ei sähköä' ? 0 : 15) + " €", 
                  bgColor: "bg-gray-300" 
                },
                { 
                  title: "Ei sähköä", 
                  title2: "0 €",
                  cursor: "cursor-pointer", 
                  onClick: () => select("Ei sähköä"), 
                  value: !spot.electricityType || spot.electricityType === "Ei sähköä" ? <FcElectricity/> : <BiSolidMinusCircle/>, 
                  bgColor: "bg-gray-300", 
                  border: "border-4", 
                  bgBorder: !spot.electricityType || spot.electricityType === "Ei sähköä" ? "border-blue-400" : "bg-gray-300"
                },          
                { 
                  title: "Valovirta",
                  title2: "230 V = 15 €", 
                  cursor: "cursor-pointer", 
                  onClick: () => select("230V"), 
                  value: spot.electricityType=== "230V" ? <FcElectricity/> : <BiSolidMinusCircle/>, 
                  bgColor: "bg-gray-300", 
                  border: "border-4", 
                  bgBorder: spot.electricityType === "230V" ? "border-blue-400" : "bg-gray-300"
                },
                { 
                  title: "Voimavirta", 
                  title2: "400 V = 15 €", 
                  cursor: "cursor-pointer", 
                  onClick: () => select("400V"), 
                  value: spot.electricityType === "400V" ? <FcElectricity/> : <BiSolidMinusCircle/>, 
                  bgColor: "bg-gray-300", 
                  border: "border-4", 
                  bgBorder: spot.electricityType === "400V" ? "border-blue-400" : "bg-gray-300"
                },
              ].map((item, index) => (
                <div
                  key={index} onClick={item.onClick}
                  className={`flex flex-col items-center ${item.cursor} ${item.bgColor} ${item.border ? item.border : null} ${item.bgBorder} p-3 rounded-lg w-full`}
                >
                  <span className="text-md text-black">{item.title}</span>
                  {item.title2 ? 
                  <span className="text-xs text-gray-500">{item.title2}</span> 
                  : <span className="text-xs text-gray-500">-------</span>  }
                  <span className="text-lg font-semibold mt-2">{item.value}</span>
                </div>
              ))}
            </div>)
      }


      <div className='flex flex-col items-center  mt-2'>
      {isAdmin() && (spot.status === 'Tilattu' || spot.status === 'Laskutettu') ?
          <Button name="Merkkaa maksetuksi" type="CANCEL" action={() => changeStatus(spot.id, "Maksettu")} />
          //<div onClick={() => changeStatus(spot.id, "Maksettu")} variant="contained" color="primary">Merkkaa maksetuksi</div>
          : null
      }

      {isAuthenticated() && spot.status === SPOT_STATUS_FREE ?
      
      <Button name="Varaa" icon={<FaLockOpen />} type="PRIMARY" color="bg-blue-500" action={() => lock(spot, SPOT_STATUS_LOCKED)} />                              
      : null
      }
      
      {auth.user?.userID === spot?.userID && (spot.status === SPOT_STATUS_LOCKED) ?
      <Button name="Poista lukitus" icon={<FaLock />} type="SECONDARY" color="bg-red-500" action={() => unlock(spot, SPOT_STATUS_FREE)} />          
      : null
      }

      {(preorder || auth.user?.userID === spot?.userID) && (spot.status === SPOT_STATUS_PREORDERED) ?
      <Button name="Poista varaus" icon={<FaLock />} type="SECONDARY" color="bg-red-500" action={() => unlock(spot, SPOT_STATUS_FREE)} />          
      : null
      }

      {auth.user?.userID === spot?.userID && (spot.status === SPOT_STATUS_ORDERED) ?
      <Button name="Poista varaus" icon={<FaLock />} type="SECONDARY" color="bg-red-500" action={() => unlock(spot, SPOT_STATUS_FREE)} />          
      : null
      }
        
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => removeOrder()}
        msg = {`Paikka ${spot.name} on varattu tai ennakkovarattu sinulle.\n\nHaluanto poistaa varauksen?`}        
      />

      <ConfirmationModal
        isOpen={isElectricModalOpen}
        onClose={() => setElectricModalOpen(false)}
        onConfirm={() => changeElectricType()}
        msg = {`${electriModalMessage}`}     
        confirmButtonTxt = "Vaihda"   
      />

    </div>);

    
};

export default SpotCard;