import React, {useState, useEffect} from 'react';
import SpotCard from '../components/SpotCard';
import {toast} from 'react-toastify';
import { useSpotContext } from '../context/SpotProvider';
import { useAuth } from '../context/AuthProvider';
import { SpotMenu } from '../components/SpotMenu';
// import GetTheme from '../MuiTheme';

const SPOT_STATUS_LOCKED = process.env.REACT_APP_SPOT_STATUS_LOCKED;
const SPOT_STATUS_FREE = process.env.REACT_APP_SPOT_STATUS_FREE;
const SPOT_STATUS_PREORDERED = process.env.REACT_APP_SPOT_STATUS_PREORDERED;
const SPOT_STATUS_ORDERED = process.env.REACT_APP_SPOT_STATUS_ORDERED;
const SPOT_STATUS_INVOICED = process.env.REACT_APP_SPOT_STATUS_INVOICED;
const SPOT_STATUS_PAYED = process.env.REACT_APP_SPOT_STATUS_PAYED;

function ShowSpotsList () {
  //const navigate = useNavigate();
  const [showSpots, setShowSpots] = useState('Kaikki');  
  const [sizeFilter, setSizeFilter] = useState([]);
  const [warnShown, setWarnShown] = useState(false);
  const [loaded, setLoaded] = useState(false);  
  const [filteredSpots, setFilteredSpots] = useState([]);
  const {spots, getOrders} = useSpotContext();
  const {auth} = useAuth();


  

  useEffect(() => {
      if(!auth?.user && !warnShown) {        
        toast.warn("Rekisteröidy tai kirjaudu mikäli haluat tehdä varauksen!", {id: 1});    
        setWarnShown(true);
      }
  },[]);

  const spotsLoaded = () => {
    setLoaded(true);
}

  useEffect(() => {

    console.log("Get filtered spots");

    let filtered = spots.filter(s => showSpots === "Kaikki" || (showSpots === "Vapaat" && (s.status === SPOT_STATUS_FREE || s.status === SPOT_STATUS_LOCKED)) || 
    (showSpots === "Varatut" && (s.status === SPOT_STATUS_ORDERED ||  s.status === SPOT_STATUS_INVOICED || s.status === SPOT_STATUS_PAYED || s.status === "Maksu paikalla")) || (s.userID === auth.user?.userID && ((showSpots === "Valitut" && (s.status === SPOT_STATUS_LOCKED || s.status === SPOT_STATUS_PREORDERED)) || (showSpots === "Omat"))));

    filtered = sizeFilter.length
    ? filtered.filter((spot) => sizeFilter.some(item => item.size.includes(spot.size)))
    : filtered; // If no sizes selected, show all spots

    setFilteredSpots(filtered);

  },[showSpots, sizeFilter, spots]);

  useEffect(() => {
    //let isMounted = true;
    
    getOrders(spotsLoaded);    

    const interval = setInterval(() => {
      getOrders(spotsLoaded);
    }, 60000); // 60 seconds

    return () => clearInterval(interval); // Clenup on unmount

    //return () => {
    //    isMounted = false;
    //    controller.abort();
    //}
  },[]);


  return (
    <div>

    { loaded ?  (
      <>
        <SpotMenu showSpots={showSpots} setShowSpots={setShowSpots} sizeFilter={sizeFilter} setSizeFilter={setSizeFilter} />
      
        { spots?.length ? (     
           <>
            {/*<div className="mt-16 w-full">*/}
            <div className="mt-16 grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 gap-2 sm:w-full">
            { //filteredSpots.filter(s => showSpots === "Kaikki" || (showSpots === "Vapaat" && (s.status === SPOT_STATUS_FREE || s.status === SPOT_STATUS_LOCKED)) || 
              //(s.userID === auth.user?.userID && ((showSpots === "Valitut" && (s.status === SPOT_STATUS_LOCKED || s.status === SPOT_STATUS_PREORDERED)) || (showSpots === "Omat")))).map((spot,i) => {
                filteredSpots.map((spot, i) => {
                //<li key={i}>{order?.name}</li>                
                    return <SpotCard spot={spot} id={i} key={spot.id} panel={i}/>                
                })
            } 
            
            </div>                        
            </>) : ( <div className='center-text'> <p>Loading</p> </div> ) }
        
      </>) : ( "Loading" ) }

        
        
         
            
            {/*
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2 }} spacing={2}>
                <Button type="submit" variant="contained" color="primary">Lähetä</Button>
                <Button variant="contained"  color="secondary" href="/">Takaisin</Button>
            </Stack>        
            */}
    </div>
  );
};

export default ShowSpotsList;