import React, {useState, useEffect} from 'react';
import { useNavigate }  from 'react-router-dom';
import { Button } from '../components/Button';
import Stack from '@mui/material/Stack';
import CompanyCard from '../components/CompanyCard';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {toast} from 'react-toastify';
import { useSpotContext } from '../context/SpotProvider';
import { useAuth } from '../context/AuthProvider';
// import GetTheme from '../MuiTheme';



function MyCompanies ({selectView=false}) {
  const navigate = useNavigate();
  const [showSpots, setShowSpots] = useState('Kaikki');  

  const {selectedCompanyID} = useSpotContext();
  const {auth} = useAuth();

 

  return (
    <div>

    
        {/*</div><div className="px-4 py-2 rounded-lg inline-block">*/}
        <div className='shadow rounded-lg p-4 m-auto mt-2 flex flex-col justify-between items-center'>    
          <Button name="Lisää uusi" type="PRIMARY" action={() => navigate('/lisaa_yritys')} />      
        </div>  
        { auth?.companies ? (     
            <div>               
            { auth.companies.map(comp => {
                //<li key={i}>{order?.name}</li>                
                    return <CompanyCard company={comp} id={comp.companyID} key={comp.companyID} panel={comp.companyID} selectView={selectView} selectedCompanyID={selectedCompanyID}/>                
                })
            } 
            
            </div>                        
            ) : <div className='center-text'> <p>Loading</p> </div>
        }

        
        
         
            
            {/*
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2 }} spacing={2}>
                <Button type="submit" variant="contained" color="primary">Lähetä</Button>
                <Button variant="contained"  color="secondary" href="/">Takaisin</Button>
            </Stack>        
            */}
    </div>
  );
};

export default MyCompanies;