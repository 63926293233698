import React, {useState} from 'react'
import { useSpotContext } from '../context/SpotProvider';
import { useAuth } from '../context/AuthProvider';
import { Button } from '../components/Button';
import { useNavigate } from 'react-router-dom';
import SpotCard from '../components/SpotCard';
import MyCompanies from './MyCompanies';
import SpotElectricityCard from '../components/SpotElectricityCard';




export const ComfirmOrder1 = () => {
  const { spots } = useSpotContext();
  const { auth } = useAuth();
  
  const filteredSpots = spots.filter(spot => (spot.status === 'Lukittu' || spot.status === "Ennakkovaraus") && spot.userID === auth.user.userID);

  const spot_count = filteredSpots.length;

  const navigate = useNavigate();

  const confirm = () => {
    navigate("/vahvista-myyja");
  }

  const goBack = () => {
    navigate(-1);
  }


  return (
    <div>

      <h1 className="text-2xl text-blue-800 text-center mt-4">Tarvitsetko sähköä?</h1>

      <h1 className="text-sm text-white text-center mt-4">Valitse alta sähköt jokaiselle valitsemallasi paikalle tarpeesi mukaan.</h1>

      <h1 className="text-sm text-white text-center mt-4">Sähköstä peritään 15 € / paikka.</h1>

      <div className='shadow rounded-lg p-4 m-auto mt-2 flex flex-col justify-between items-center'>
        
        <div className='flex flex-row w-auto space-x-2 mt-2 sm:mt-0'>        
          <Button name="Seuraava" type="PRIMARY" action={() => confirm()} />                    
          <Button name="Takaisin" type="CANCEL" action={() => goBack()} />          
        </div>
      </div>
      {/*
      <div className="mt-4 grid grid-cols-1 place-items-center mx-auto lg:grid-cols-3 sm:grid-cols-2 gap-2 "> 
      */}
      {/*<div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:w-full place-items-center">*/}
      <div className={`mt-4 gap-2 grid sm:w-full  ${
      spot_count == 1 ? "grid-cols-1" : spot_count == 2 ? "grid-cols-1 sm:grid-cols-2 "
      : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
      }`}
>
        {filteredSpots.map(spot => {
            //return <SpotElectricityCard spot={spot}/>
            return <SpotCard spot={spot}/>
        })}
      </div>
    </div>
  )
}
