import React, {useState, useEffect} from 'react'
import useAxios from '../api/axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate, useParams} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthProvider';

export const EditCompany = () => {

    const navigate = useNavigate();
    const {companyID} = useParams();
  
    const [name, setName] = useState('');
    const [invoicingEmail, setInvoicingEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [publicPhone, setPublicPhone] = useState('');
    const [publicEmail, setPublicEmail] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [postalcode, setPostalcode] = useState('');
    const [city, setCity] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [description, setDescription] = useState('');
    const [homepage, setHomepage] = useState('');

    const {auth, setAuth} = useAuth();

    

  useEffect(() => {
    auth.companies.filter(comp => comp.companyID === companyID).forEach(comp => {
      setName(comp.name);
      setPublicEmail(comp.publicEmail);
      setInvoicingEmail(comp.invoicingEmail);
      setPhone(comp.phone);
      setPublicPhone(comp.publicPhone);
      setAddress(comp.address);
      setAddress2(comp.address2);
      setPostalcode(comp.postalcode);
      setCity(comp.city);
      setFacebook(comp.facebook);
      setInstagram(comp.instagram);
      setHomepage(comp.homepage);
      setDescription(comp.description);
    })    
    },[]);


    

    const api =useAxios();

    function validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let ok = true;

      
      if (!emailRegex.test(invoicingEmail)) {
          toast.error("Tarkista laskutuksen sähköposti osoite");
          ok = false;
      }
      
      if (publicEmail && !emailRegex.test(publicEmail)) {
        toast.error("Tarkista julkinen sähköposti osoite");
        ok = false;
    }

      if(name.trim().length === 0) {
          toast.error("Anna yrityksen, yhdistyksen tai myyjän nimi")
          ok = false;
      }
      
      if(phone.trim().length === 0) {
          toast.error("Anna puhelinumerosi")
          ok = false;
      }

      return ok;
  }

  const handleSubmit = async (e) => {
          e.preventDefault();    
          
          if(!validateForm()) {
              return;
          }
  
          try {

             const editCompany = { companyID, name, phone, invoicingEmail, address, address2, postalcode, city, publicEmail, publicPhone, homepage,facebook, instagram, description};

              const response = await api.post('/editCompany',
                  JSON.stringify({ company: editCompany }),
                  {
                      headers: { 'Content-Type': 'application/json'},                    
                  }
              );
                             
              setAuth( {...auth, companies: auth.companies.map(comp => comp.companyID === companyID ? {...comp, ...editCompany} : comp)});
              toast.info("Tiedot päivitetty");
              navigate(-1);              
          } catch(err) {
              if(!err?.response) {
                  toast.error('Odottomaton virhe ' . err);
                  //setErrMsg("Ei vastausta palvelimelta");
              } else if(err.response?.status === 400) {
                  toast.error("Palvelin virhe");
                  //setErrMsg("Palvelin virhe");
              } else if(err.response?.status === 401) {
                  toast.error("Tunnukset eivät kelpaa");
                  //setErrMsg("Tunnukset eivät kelpaa");
              } else {
                  toast.error("Tietojen päivitys epäonnistui");
                  //setErrMsg("Kirjautuminen epäonnistui");
              }
              //errRef.current.focus();
          }        
      }

      const handleGoBack = () => {
        navigate(-1); // Navigates back to the previous page
      };

  return (
    <>
    <div className="w-full h-full mx-auto text-center flex  flex-col">                
        <h1 className="text-4xl pb-2 mb-4 font-bold text-blue-800">Myyjän tiedot</h1>
        <h1 className="text-sm text-white mb-2">(*) pakolliset kentät</h1>
        <form onSubmit={handleSubmit}>          
        {/*
        <div className="max-w-[400px] mt-2 w-full h-full mx-auto text-center flex  flex-col">      
        */}
        <div className="max-w-[800px] mt-2 w-full h-full mx-auto text-center flex flex-col md:flex-row gap-4">
        <div className="flex-1">
        <h1 className="text-sm text-white">Laskutustiedot</h1>
        <h1 className="text-sm text-white mb-2">vain järjestäjän kayttöön</h1>  
        <Stack direction="column" className="bg-blue-500 px-2 py-2" alignItems="center" justifyContent="center" spacing={2}>
        <TextField
                  variant="filled"
                  //margin="normal"
                  required                  
                  id="name"
                  label="Yrityksen / yhdistyksen / myyjän nimi"
                  name="name"
                  autoComplete="nimi"
                  autoFocus
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setName(e.target.value)}
                  value={name}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"
                  required                  
                  id="email"
                  label="Sähköposti (laskutukseen)"
                  name="invoicingEmail"
                  autoComplete="sähköposti"   
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}               
                  onChange={e => setInvoicingEmail(e.target.value)}
                  value={invoicingEmail}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"
                  required                  
                  id="phone"
                  label="Puhelin"
                  name="phone"
                  autoComplete="phonenumber"    
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}               
                  onChange={e => setPhone(e.target.value)}
                  value={phone}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="address"
                  label="Katuosoite"                                 
                  id="katuosoite1"
                  autoComplete="katuosoite"
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setAddress(e.target.value)}
                  value={address}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="address2"
                  label="Katuosoite 2"                  
                  id="katuosoite2"                  
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setAddress2(e.target.value)}
                  value={address2}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="postalcode"
                  label="Postinumero"                                
                  id="postalcode"
                  autoComplete="postinumero"
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setPostalcode(e.target.value)}
                  value={postalcode}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="city"
                  label="Toimipaikka"                           
                  id="toimipaikka"
                  autoComplete="toimipaikka"
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setCity(e.target.value)}
                  value={city}  
                />                                                
            </Stack>
            </div>
            <div className="flex-1">
            <h1 className="text-sm text-white">Julkiset tiedot</h1>
            <h1 className="text-sm text-white mb-2">kaikkien sivuston kävijöiden nähdävillä, kun paikka on maksettu</h1>
        <Stack direction="column" className="bg-blue-500 px-2 py-2" alignItems="center" justifyContent="center" spacing={2}>
        <TextField
                  variant="filled"
                  //margin="normal"
                  required                  
                  id="name"
                  label="Yrityksen / yhdistyksen / myyjän nimi"
                  name="name"
                  autoComplete="nimi"
                  autoFocus
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}
                  InputProps={{
                    readOnly: true, // Makes the field read-only
                  }}       
                  value={name}            
                />
                <TextField
                  variant="filled"
                  //margin="normal"                      
                  id="email"
                  label="Sähköposti"
                  name="publicEmail"
                  autoComplete="sähköposti"   
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}               
                  onChange={e => setPublicEmail(e.target.value)}
                  value={publicEmail}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                
                  id="phone"
                  label="Puhelin"
                  name="phone"
                  autoComplete="phonenumber"    
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }}               
                  onChange={e => setPublicPhone(e.target.value)}
                  value={publicPhone}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="homepage"
                  label="Kotisivut"                           
                  id="homepage"
                  autoComplete="homepage"
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setHomepage(e.target.value)}
                  value={homepage}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="facebook"
                  label="Facebook"                                 
                  id="facebook"
                  autoComplete="facebook"
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setFacebook(e.target.value)}
                  value={facebook}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="instagram"
                  label="Instagram"                  
                  id="instagram"                  
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setInstagram(e.target.value)}
                  value={instagram}  
                />
                <TextField
                  variant="filled"
                  //margin="normal"                                    
                  name="description"
                  label="Kuvaus, myytävät tuotteet tms."                                
                  id="description"                  
                  sx={{
                    width: 1,
                    backgroundColor: "white", // Light gray background
                    borderRadius: "8px",        // Optional: Rounded corners
                  }} 
                  onChange={e => setDescription(e.target.value)}
                  value={description}  
                />
                
                
                
                
            </Stack>
            </div>
            </div>

            
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{p: 2}} spacing={2}>
                <Button type="submit" variant="contained" color="primary">Päivitä</Button>                
                <Button variant="contained"  color="secondary" onClick={handleGoBack}>Takaisin</Button>
            </Stack>
            
        </form>
    </div>
    </>
  )
}
