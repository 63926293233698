import React, {useReducer} from 'react'
import { ToastContainer } from 'react-toastify';
import MessageReducer from '../context/MessageReducer';


export const Toaster = () => {
    const [msg, dispatch] = useReducer(MessageReducer,{});



  return (
    <ToastContainer position="top-center" autoClose={3000} />
  )
}
