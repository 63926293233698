
import React, {useState,useEffect} from 'react';
import { useSpotContext } from '../context/SpotProvider';
import { Button }  from './Button';
import { useAuth } from '../context/AuthProvider';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import useAxios from '../api/axios';
const CompanyCard = ({ company, selectView=false, selectedCompanyID }) => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [borderColor, setBorderColor] = useState("border-white");
    
    const { auth,deleteCompany, setSelectecCompanyID } = useAuth();

    const api = useAxios();

    const navigate = useNavigate();

    

    useEffect(() => {
      if(selectView && company.companyID === auth.selectedCompanyID) {
        setBorderColor('border-blue-500');
      } else {
        setBorderColor('border-white');
      }     
  },[auth]);

    const delCompany = async () => {   
      
      console.log("Deleting company " + company.companyID);

      try {
        const response = await api.post('/deleteCompany',
              JSON.stringify({ companyID: company.companyID }) ,
              {
                  headers: { 'Content-Type': 'application/json'},                    
              }
          );
        
          deleteCompany(company.companyID);
          toast.info("Myyjän tiedon poistettu!");     
          
          //Refresh the page
          setTimeout(() => {
            window.location.reload();
          }, 5000);

        } catch(err) {
            if(!err?.response) {
                toast.error("Odottomaton virhe!");
                //setErrMsg("Ei vastausta palvelimelta");
            } else if(err.response?.status === 400) {
                toast.error(`${err.response?.data?.msg}`);
                //setErrMsg("Palvelin virhe");
            } else if(err.response?.status === 401) {
                toast.error("Tunnukset eivät kelpaa");
                //setErrMsg("Tunnukset eivät kelpaa");
            } else {
                toast.error("Poistaminen epäonnistui");
                //setErrMsg("Kirjautuminen epäonnistui");
            }
            //errRef.current.focus();
        }          
        setModalOpen(false);
  }

  const selectCompany = (companyID) => {
    setSelectecCompanyID(companyID);
  }
    
    const editCompany = async (company) => {                
        navigate("/muokkaa-myyjaa/" + company.companyID);
    }


    //<div className={`${borderColor} border-8 bg-white shadow rounded-lg p-2 mr-4 ml-4 mt-2 flex flex-col sm:flex-row justify-between items-start sm:items-center`}> 
  return (
    
      
      
      <div className={`bg-white shadow-lg rounded-xl p-2 m-4 border-8 ${borderColor} `}>
      {/*<div className='flex flex-col space-y-2 sm:flex-row sm:space-x-2'>    */}
      <div className={`grid grid-cols-1 sm:grid-cols-3 gap-2`}>     
      <div className="bg-gray-200 rounded-lg p-4 pt-2 space-y-1">
         <span className="font-bold text-center text-lg">Laskutustiedot</span>
          {[
            { title: "Nimi:", value: company.name },
            { title: "Osoite:", value: `${company.address}${company.address2 && ' ' + company.address2}${company.postalcode || company.city ? ',' : ''}${company.postalcode && ' ' + company.postalcode}${company.city && ' ' + company.city}` },
            { title: "Puhelin:", value: company.phone },
            { title: "Sähköposti:", value: company.invoicingEmail },
          ].map((item, index) => (
            <div key={index} className="flex">
              <p className="w-24 font-semibold text-black">{item.title}</p> 
              {/* Adjust "w-28" to change alignment spacing */}
              <p className="text-sm text-gray-800">{item.value}</p>
            </div>
          ))}
      </div>
      <div className="bg-gray-200 rounded-lg p-4 pt-2 space-y-1">
      <span className="font-bold text-center text-lg">Julkaistavat tiedot</span>
          {[
            { title: "Sähköposti:", value: company.publicEmail },            
            { title: "Puhelin:", value: company.publicPhone },
            { title: "Kotisivu:", value: company.homepage },
            { title: "Facebook:", value: company.facebook },
            { title: "Instragram:", value: company.instagram },
          ].map((item, index) => (
            <div key={index} className="flex">
              <p className="w-24 font-semibold text-black">{item.title}</p> 
              {/* Adjust "w-28" to change alignment spacing */}
              <p className="text-sm text-gray-800">{item.value}</p>
            </div>
          ))}
        </div>
        <div className="bg-gray-200 rounded-lg p-4 pt-2 space-y-1">         
              <p className="w-30 font-semibold text-black">Kuvaus tuotteista, palveluista tai toiminnasta</p>               
              <p className="text-sm text-gray-800">{company.description}</p>         
        </div>
                
      </div>

      {/*<div className='flex flex-col sm:flex-row w-full sm:w-auto sm:space-x-2 mt-2 sm:mt-0'> */}
      <div className='flex flex-row items-center justify-center space-x-2 mt-2'>
        {selectView && auth.selectedCompanyID != company.companyID && 
          <Button name="Valitse" type="PRIMARY" action={() => selectCompany(company.companyID)} />
        }           
        <Button name="Muokkaa" type="EDIT" action={() => editCompany(company)} />
        <Button name="Poista" type="DELETE" action={() => setModalOpen(true)} />
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => delCompany()}
        msg = {`Haluanto poistaa myyjän ${company.name} tiedot?`}        
      />
        
    </div>
  );
};

export default CompanyCard;