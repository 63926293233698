import React, { useState } from 'react';
import { FaUser, FaSignInAlt, FaSignOutAlt, FaBuilding, FaWindowClose } from 'react-icons/fa';
import { FaHelmetSafety } from "react-icons/fa6";
import { ImAddressBook, ImProfile } from "react-icons/im";
import { AiOutlineMenu } from 'react-icons/ai';
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import lauri_logo from '../logo192.png';
import { useAuth } from '../context/AuthProvider';
import { useSpotContext } from '../context/SpotProvider';
import { PiArrowFatLineRight } from "react-icons/pi";

const SPOT_STATUS_LOCKED = process.env.REACT_APP_SPOT_STATUS_LOCKED;
const SPOT_STATUS_FREE = process.env.REACT_APP_SPOT_STATUS_FREE;

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const { isAuthenticated,setAuth, auth } = useAuth();
  const {spots, unlockSpot} = useSpotContext();
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = () => {
        
      console.log("Navbar - handleLogout");
      spots.filter(spot => spot.userID = auth.user?.userID && spot.status === SPOT_STATUS_LOCKED).forEach(spot => {
        unlockSpot(spot,SPOT_STATUS_FREE);
      })
      
      setAuth({});
      toast.info("Uloskirjautuminen onnistui");      
      navigate("/");
  };

  return (

        /*
        <div className='flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4 text-white'>
          <div className="flex"> 
                <img src={lauri_logo} alt="lauri-logo" className="mt-4 h-8 w-12 mr-6 pr-2"/><br/>                    
                <h1 className='w-full text-3xl font-bold text-white'>Laurin markkinat</h1>
            </div>
        */ 
     
    <div className='fixed top-0 left-0 w-full bg-gray-100 z-40'>  
    <div className="w-1/1 h-16 bg-blue-500 flex px4 mx-auto items-center text-white text-xl font-semibold">
        
        <div className="flex items-center space-x-3 ml-4">
          <a href="/"><img src={lauri_logo} alt="lauri-logo" className="h-9 w-auto" /></a>
          <h1 className="text-1xl font-bold">Laurin markkinat</h1>
        </div>    
        <div className='fixed  right-10'>
      <ul className='hidden md:flex'>
            
                                
                {!isAuthenticated() && (
                <>
                  <li className='p-2'>
                    <Link to='/login'
                    className='rounded-md px-2 text-sm font-medium text-white hover:text-gray-700'>
                    <FaSignInAlt className='inline mr-1' />Kirjaudu
                  </Link>
                  </li>
                  <li className='p-2'>
                  <Link to='/register'
                    className='rounded-md px-2 text-sm font-medium text-white hover:text-gray-700'>
                    <FaUser className='inline mr-1' />Rekisteröidy
                  </Link>
                  </li>
                </>
              )}          
               
               {isAuthenticated() && (
                <>

                
                  <li className='p-4'>
                  <button
                    onClick={handleLogout}
                    className='mx-3 text-sm text-white hover:text-gray-600'
                  >
                    <FaSignOutAlt className='inline mr-1' /> Kirjaudu ulos
                  </button>
                  </li>
                </>
              )}     
      </ul>      
      </div>
      <div onClick={handleNav} className='fixed right-4 hover:text-gray-700'>
          <AiOutlineMenu size={20}/>
          {/*nav ? null : <AiOutlineMenu size={20} />*/}
      </div>
      <ul className={nav ? 
  'fixed right-0 top-0 w-64 h-full max-h-[100vh] overflow-y-auto overflow-x-hidden border-4 border-r-gray-900 bg-blue-500 ease-in-out duration-2500' 
  : 'ease-in-out duration-500 fixed left-[-100%]'}
>
  <span 
    onClick={() => setNav(false)} 
    className="flex flex-row text-white hover:text-gray-600 text-xl cursor-pointer absolute top-4 right-4"
  >
    Sulje <PiArrowFatLineRight className="ml-2 mt-1.5" />
  </span>

  <h1 className='w-full text-2xl font-bold text-gray-700 mt-16 mb-1 m-4'>Laurin Markkinat</h1>
  <h1 className='w-full text-1xl font-bold text-gray-700 text-center'>Valikko</h1>

  <div className="flex items-center justify-center mt-2 w-full m-auto">
    <img src={lauri_logo} alt="lauri-logo" className="h-9" />
  </div>

  <li className='p-4 border-b border-gray-600'></li>

  {!isAuthenticated() && (
    <>
      <li className='p-4 border-b border-gray-600'>
        <Link to='/login' onClick={handleNav}
          className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
          <FaSignInAlt className='inline mr-1' />Kirjaudu
        </Link>
      </li>
      <li className='p-4 border-b border-gray-600'>
        <Link to='/register' onClick={handleNav}
          className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
          <FaUser className='inline mr-1' />Rekisteröidy
        </Link>
      </li>
    </>
  )}

  <li className='p-4 border-b border-gray-600'>
    <Link onClick={handleNav} to='/myyntipaikat-lista'
      className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
      Myyntipaikat - lista
    </Link>
  </li>

  <li className='p-4 border-b border-gray-600'>
    <Link onClick={handleNav} to='/myyntipaikat-kartta'
      className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
      Myyntipaikat - kartta
    </Link>
  </li>

  {isAuthenticated() && (
    <>
      <li className='p-4 border-b border-gray-600'>
        <Link to='/profiili'
          className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
          <ImProfile className='inline mr-1' />Oma profiili
        </Link>
      </li>

      <li className='p-4 border-b border-gray-600'>
        <button onClick={handleLogout}
          className='mx-3 text-sm text-white hover:text-gray-600'>
          <FaSignOutAlt className='inline mr-1' /> Kirjaudu ulos
        </button>
      </li>
    </>
  )}

  <li className='p-4'>
    <Link to='/yhteystiedot' onClick={handleNav}
      className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
      <ImAddressBook className='inline mr-1' />Yhteystiedot
    </Link>
  </li>
</ul>
    </div>
    </div>
  );
};

export default Navbar;