import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";

function NotFound () {

  const navigate = useNavigate();
 
  return (
    <div className="max-w-[800px] mt-[96px] w-full h-full mx-auto text-center flex  flex-col">                
        <h1 className="text-4xl pb-6 mb-4 font-bold">404</h1>
        <h1 className="text-4xl pb-6 mb-4 font-bold">Sivua ei löydy</h1>      
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ p: 2 }} spacing={2}>                    
            <Button variant="contained" color="secondary" onClick={() => navigate("/")}>Päävalikkoon</Button>  
        </Stack>   
    </div>
  );
};

export default NotFound;