import React from "react";
import lions_logo from '../lion_logo.gif';
import lauri_logo from '../lauri_logo.png';


function Home() {
  return (
    <div className="max-w-[800px] mt-[50px] w-full h-full mx-auto text-center flex  flex-col">
        {/*
        <div>
            <img src={lauri_logo} alt="lauri-logo" className="block mx-auto h-40"/><br/>                    
        </div>
        */}
        <p className="text-4xl pt-6 mb-2 font-bold text-white">MYNÄMÄEN</p>
        <h1 className="text-blue-800 text-7xl  mb-2 font-bold">Laurin Markkinat</h1>
        
        <h3 className="text-4xl pt-4 font-bold text-white">9.8.2025</h3>
        <h3 className="text-2xl pt-6 text-white">9.00 - 15.00</h3>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
            <img src={lions_logo} alt="lions-logo" className="block mx-auto h-40"/><br/>                    
        </div>
        {/*
        <Stack direction="row" spacing={2}>
            <Button className="App-button" variant="outlined" href="/osta-aikaa">Lahjoita</Button>  
            <Button className="App-button" variant="outlined" href="/varaa-lista">Varaa myyntipaikka</Button>  
            <Button className="App-button" variant="outlined" href="/admin">Kirjaudu</Button>  
        </Stack>            
        */}
    </div>
    );

}

export default Home;
