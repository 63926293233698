
import { createContext, useContext, useReducer, useCallback } from "react";
import SpotReducer from "./SpotReducer";
import useAxios from '../api/axios';
import { toast } from "react-toastify";
import { useNavigate , useLocation} from "react-router-dom";


const SPOT_STATUS_LOCKED = process.env.REACT_APP_SPOT_STATUS_LOCKED;
const SPOT_STATUS_FREE = process.env.REACT_APP_SPOT_STATUS_FREE;
const SPOT_STATUS_PREORDERED = process.env.REACT_APP_SPOT_STATUS_PREORDERED;
const SPOT_STATUS_ORDERED = process.env.REACT_APP_SPOT_STATUS_ORDERED;
const SPOT_STATUS_INVOICED = process.env.REACT_APP_SPOT_STATUS_INVOICED;
const SPOT_STATUS_PAYED = process.env.REACT_APP_SPOT_STATUS_PAYED;
const SpotContext = createContext({spots: []});

export const useSpotContext = () => useContext(SpotContext);


export function SpotProvider({ children }) {
    const [data, dispatch] = useReducer(SpotReducer,{spots: []});
    const api = useAxios();

    const navigate = useNavigate();
    const location = useLocation();    

    const controller = new AbortController();


    const getLockedSpots = (userID) => {
      return data.spots.filter(spot => (spot.status === SPOT_STATUS_LOCKED || spot.status === SPOT_STATUS_PREORDERED) && spot.userID === userID);
    }

    const getPreOrders = async (preOrderID, setLoaded, setErrMsg) => {          
      try {
          const response = await api.post('/getPreorders' , 
            JSON.stringify({ preOrderID }),
            {
                headers: { 'Content-Type': 'application/json'},                    
            }    
          );          
          
          dispatch({ type: "SET", payload: response.data });
          //setOrders(response.data);              
          setLoaded(true);

      } catch(err) {

        if(!err?.response) {
                        toast.error("Odottomaton virhe!");
                        setErrMsg("Odottomaton virhe! Kokeile uudelleen ja ota yhteyttä paikkamyyjään jos virhe toituu.");
                    } else if(err.response?.status === 400) {
                        toast.error(`${err.response?.data?.msg}`);
                        setErrMsg(err.response?.data?.msg);
                    } else {
                        toast.error("dottomaton virhe!");
                        setErrMsg("Odottomaton virhe! Kokeile uudelleen ja ota yhteyttä paikkamyyjään jos virhe toituu.");
                    }
      }
    }

    const getOrders = async (setLoaded) => {
      console.log("getOrders");
      try {
          const response = await api.get('/spots?activityID=4' , {
              signal: controller.signal
          });          
          //console.log(response.data);          
          //isMounted && dispatch({ type: "SET", payload: response.data });
          dispatch({ type: "SET", payload: response.data });
          setLoaded(true);
      } catch(err) {
          console.error(err);
          toast.error("Paikkojen päivitys ei onnistunut");
          //navigate('/login', { state: {from: location}, replace: true});
      }
    }

    /*
    useEffect(() => {
        //let isMounted = true;
        
    
        getOrders();

        const interval = setInterval(() => {
          getOrders();
        }, 5000); // 10 seconds
    
        return () => clearInterval(interval); // Cleanup on unmount
    
        //return () => {
        //    isMounted = false;
        //    controller.abort();
        //}
    },[]);
    */

    const selectSpot = (spot) => {
      console.log("selectSpot");
      dispatch({ type: "UPDATE", payload: spot });
    }


    const getJustOrdered = () => {
      return data.justOrdered;
    }

    const setJustOrdered = (spots) => {
      dispatch({type: "JUST_ORDERED", payload: { justOrdered: spots }});

      
      console.log(data.spots.lenght);
    }


    const setElectricity = async (spotID, electricityType, setOnServer=false) => {      
      console.log("setElectricity");

      let callOK = true;
      if(setOnServer) {
        try {
          const response = await api.post('/setElectricityType' , 
              JSON.stringify({ spotID,  electricityType }),
              {
                  headers: { 'Content-Type': 'application/json'},                    
              }
          );
                    
        } catch(err) {
            callOK = false;            
            console.error(err);          
            toast.error("Sähkötyypin vaihto epäonnistui. Yritä uudelleen.");          
                        
        }    
      }


      if(callOK) {
        dispatch({ type: "SET_ELECTRICITY", payload: {spotID, electricityType} });
      }

      return callOK;
      
    }
    

    const updateSpot = (spot) => {
      console.log("updateSpot");
      dispatch({ type: "UPDATE_STATUS", payload: {spot: spot, userID: spot.userID, newStatus: spot.status, locked: null} });  
    }

    const lockSpot = async (spot, userID, newStatus) => {
      console.log("lockSpot");
      try {
        const response = await api.post('/lockSpot' , 
            JSON.stringify({ spotID: spot.spotID, newStatus }),
            {
                headers: { 'Content-Type': 'application/json'},                    
            }
        );
        dispatch({ type: "UPDATE_STATUS", payload: {spot: spot, newStatus: newStatus, userID, locked: Date.now()} });
        //console.log("Response: " . response?.data);          
        //isMounted && dispatch({ type: "SET", payload: response.data });
        
      } catch(err) {

          //let value = JSON.stringify(err);
          console.error(err);          
          toast.error(err?.response?.data?.msg);          
          //navigate('/login', { state: {from: location}, replace: true});
      }                
    }

    const unlockSpot = async (spot, newStatus) => {
      switch(spot.status) {
        case SPOT_STATUS_FREE:
        case SPOT_STATUS_LOCKED:
          await removeLock(spot ,newStatus);
          break;
        case SPOT_STATUS_PREORDERED:
        case SPOT_STATUS_ORDERED:
          await deleteOrder(spot, newStatus);
          break;
        default:
          toast.error(`Paikkaa ei voi poistaa. Virheellinen status ${spot.status}`);
      }            
    }

    const deleteOrder = async (spot, newStatus) => {
      console.log("deleteOrder");
      try {
        const response = await api.post('/deleteOrder' , 
            JSON.stringify({ spot }),
            {
                headers: { 'Content-Type': 'application/json'},                    
            }
        );

        toast.info(`Varauksesi paikalle ${spot.name} poistettiin onnistuneesti.`);
        //console.log("Response: " . response?.data);          
        //isMounted && dispatch({ type: "SET", payload: response.data });
        dispatch({ type: "UPDATE_STATUS", payload: {spot: spot, newStatus: newStatus, locked: null} });
      } catch(err) {
          toast.error("Paikan vapauttaminen ei onnistunut. Yritä uudelleen ja ota yhteyttä ylläpitäjään mikäli virhe toistuu." );          
      }     
    }

    const removeLock = async (spot, newStatus) => {
      console.log("removeLock");
      try {
        const response = await api.post('/unlockSpot' , 
            JSON.stringify({ spotID: spot.spotID, newStatus }),
            {
                headers: { 'Content-Type': 'application/json'},                    
            }
        );
        //console.log("Response: " . response?.data);          
        //isMounted && dispatch({ type: "SET", payload: response.data });
        dispatch({ type: "UPDATE_STATUS", payload: {spot: spot, newStatus: newStatus, locked: null} });
      } catch(err) {

          let value = JSON.stringify(err);
          console.error(err);
          console.error(value?.msg);
          toast.error("Paikan vapauttaminen ei onnistunut: " . err?.message);
          console.error(err);
          navigate('/login', { state: {from: location}, replace: true});
      }     
    }

    return (
      <SpotContext.Provider value={{ selectSpot, getOrders, getPreOrders, lockSpot, unlockSpot, updateSpot, getLockedSpots, setJustOrdered, setElectricity, spots: data.spots, getJustOrdered }}>
        {children}
      </SpotContext.Provider>
    );
  }
  
  // Custom hook for using ThemeContext
  export function useSpot() {
    const context = useContext(SpotContext);
    if (!context) throw new Error("useSpot must be used within a SpotProvider");
    return context;
  }