import { useState, useEffect } from "react";


const LOCK_TIMEOUT = process.env.REACT_APP_SPOT_LOCK_TIMEOUT_SEC;

export const Timer = ({ startTime, timeoutCallBack }) => {



  const [time, setTime] = useState(String(Math.floor(LOCK_TIMEOUT / 60)).padStart(2, "0") + ":" + String(LOCK_TIMEOUT % 60).padStart(2, "0"));

  useEffect(() => {
    const startDate = new Date(startTime);
    startDate.setMinutes(startDate.getMinutes() + LOCK_TIMEOUT/60);


    console.log("Time is " + startTime)

    

    const updateTimer = () => {
      const currentTime = Date.now();
      const difference = Math.floor((startTime - currentTime) / 1000);

      if(difference <= 0) {
        timeoutCallBack();
      }
      
      console.log(`Starttime: ${startTime} - Difference: ${difference}`);

      const minutes = Math.floor(difference / 60);
      const seconds = difference % 60;

      setTime(`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`);
    };

    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [startTime]);

  return <h1>{time}</h1>;
};
