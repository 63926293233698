import React,{useState} from 'react'
import { useSpotContext } from '../context/SpotProvider';
import { useAuth } from '../context/AuthProvider';
import { toast } from 'react-toastify';
import { Button } from '../components/Button';
import { useNavigate } from 'react-router-dom';
import CompanyCard from '../components/CompanyCard';
import MyCompanies from './MyCompanies';
import useAxios from '../api/axios';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';




export const ComfirmOrder2 = () => {
  const { getLockedSpots, updateSpot, setJustOrdered } = useSpotContext();
  const { auth } = useAuth();
  const [agreement, setAgreement] = useState(false);
  //const [compSelected,setCompSelected] = useState(false);

  const navigate = useNavigate();
  const api = useAxios();

  

  const confirm = async () => {
            
      try {
          const spots = getLockedSpots(auth.user.userID);          

          const response = await api.post('/makeReservation',
              JSON.stringify({ companyID: auth.selectedCompanyID, spots }),
              {
                  headers: { 'Content-Type': 'application/json'},                    
              }
          );
                          
          spots.forEach(spot => {
            spot.status = 'Varattu';
            spot.companyID = auth.selectedCompanyID;
            spot.locked = null;
            spot.userID = auth.user.userID;
            updateSpot(spot);
          })          
          toast("Paikat varattu");
          setJustOrdered(spots);
          navigate("/kiitos-varauksesta");              
      } catch(err) {
          if(!err?.response) {
              toast.error('Odottomaton virhe');
              //setErrMsg("Ei vastausta palvelimelta");
          } else if(err.response?.status === 400) {
              toast.error("Palvelin virhe");
              //setErrMsg("Palvelin virhe");
          } else if(err.response?.status === 401) {
              toast.error("Tunnukset eivät kelpaa");
              //setErrMsg("Tunnukset eivät kelpaa");
          } else {
              toast.error("Tietojen päivitys epäonnistui");
              //setErrMsg("Kirjautuminen epäonnistui");
          }
          //errRef.current.focus();
      }            
  }

  const goBack = () => {
    navigate(-1);
  }

  const agreeTerms = () => {
    setAgreement(!agreement);
  }

  return (
    <div>

      
      <h1 className="text-2xl text-blue-800 text-center mt-4">Ilmoita myyjän tiedot</h1>
      <h1 className="text-sm text-white text-center mt-4">Pakollisten laskutustietojen lisäksi, voit antaa myös julkisesti tällä sivustolla näytettäviä tietoja.</h1>
      <h1 className="text-sm text-white text-center mt-2">Julkiset myyjän tiedot näytetään paikkalistalla, kun paikka on vahvistettu ja maksettu.</h1>
      <h1 className="text-sm text-white text-center mt-2">Myyjän nimi näytetään aina paikkalistalla.</h1>

                           
      <div>
        {/* auth?.companies ? (     
            <div>               
            { auth.companies.map(comp => {
                //<li key={i}>{order?.name}</li>                
                    return <CompanyCard company={comp} id={comp.id} key={comp.i} panel={comp.i}/>                
                })
            } 
            
            </div>                        
            ) : <div className='center-text'> <p>Loading</p> </div>
        */}
        <MyCompanies selectView={true}/>
      </div>
      <div className="flex flex-col justify-between items-center">
      <FormControlLabel 
                        key={1} 
                        control={<Checkbox 
                          checked={agreement}
                          onChange={() => agreeTerms()}
                          sx={{ 
                          transform: "scale(0.6)", 
                          color: "white",
                          height: 24,
                          '&.Mui-checked': { color: "white" }  
                        }} />} 
                        label={<span className="text-xs text-white">Olen lukenut ja hyväksyn <a href='https://www.lcmynamaki.fi/wp-content/uploads/LaurinMarkkinat/Laurin_Markkinat_2025_turvallisuusohje.pdf'
                          target="_blank" className='rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-700'>
                          Turvallisuusohjeet</a></span>}sx={{ marginBottom: 0 }}/> 
      </div>
      <div className='p-4 m-auto mt-2 flex flex-col justify-between items-center'>
        <div className='flex flex-col sm:flex-row sm:space-x-4'>
        </div>
        <div className='flex flex-col sm:flex-row w-full sm:w-auto sm:space-x-2 mt-2 sm:mt-0'>
          { auth.selectedCompanyID && agreement ? 
              <Button name="Vahvista varaus" type="PRIMARY" action={() => confirm()} />
              : <Button action={() => toast.info("Valitse tai lisää myyjä ja hyväksy turvallisuusohjeet niin voit vahvistaa varauksen!")} name="Vahvista varaus" type="DISABLED"/>
          }          
          <Button name="Takaisin" type="SECONDARY" action={() => goBack()} />          
        </div>
        
      </div>
      
    </div>
  )
}
