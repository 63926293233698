import logo from './lion_logo.gif';
import './App.css';
import AppHeader from './AppHeader'
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Login from './pages/admin/Login';
import Register from './pages/Register';
import ShowSpotsList from './pages/ShowSpotsList';
import MapPage from './pages/MapPage';
import MyProfile from './pages/MyProfile';
import { AddCompany } from './pages/AddCompany';
import { EditCompany } from './pages/EditCompany';
import { ComfirmOrder1 } from './pages/ComfirmOrder1';
import { ComfirmOrder2 } from './pages/ComfirmOrder2';
import { ComfirmOrder3 } from './pages/ComfirmOrder3';
import { RenewPWD } from './pages/admin/RenewPWD';
import { Contacts } from './pages/Contacts';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
import "react-toastify/dist/ReactToastify.css";
import { ComfirmPreorder } from './pages/ComfirmPreorder';

function App() {

  console.log("Locked Status:", process.env.REACT_APP_SPOT_STATUS_LOCKED);

  return (
    <>
            
      {/*<Header></Header>*/}
      <Navbar></Navbar>
         
      <div className="pt-16">
        
      <Routes>            
              {/* Pubblic routes */}
              <Route element={<AppHeader/>}>
                <Route path='/' element={<Home/>}></Route>                   
                <Route path="/login" element={<Login/>}></Route> 
                <Route path="/register" element={<Register/>}></Route> 
                <Route path="/myyntipaikat-lista" element={<ShowSpotsList/>}></Route>                      
                <Route path="/myyntipaikat-kartta" element={<MapPage/>}></Route>
                <Route path="/vahvista-sahko" element={<ComfirmOrder1/>}></Route>     
                <Route path="/vahvista-myyja" element={<ComfirmOrder2/>}></Route>   
                <Route path="/kiitos-varauksesta" element={<ComfirmOrder3/>}></Route>  
                <Route path="/profiili" element={<MyProfile/>}></Route>  
                <Route path="/muokkaa-myyjaa/:companyID" element={<EditCompany/>}></Route>                  
                <Route path="/lisaa_yritys" element={<AddCompany/>}></Route>                                                       
                <Route path="/yhteystiedot" element={<Contacts/>}></Route> 
                <Route path="/uusi-salasana" element={<RenewPWD/>}></Route> 
                <Route path="/vahvista_ennakko" element={<ComfirmPreorder/>}></Route> 

                <Route path="*" element={<NotFound/>}></Route>
              </Route>
                              
              {/*
              <Route element={<LogoLayout/>}>
                
              </Route>
                  

              {/* protected routes }          
              <Route element={<RequireAuth/>}>
                <Route path="/admin" element={<LogoLayout/>}>
                  <Route path="/admin" element={<AdminHome/>}></Route> 
                  <Route path="/admin/lahjoitukset" element={<Payments/>}></Route> 
                  <Route path="/admin/pyynnot" element={<Orders/>}></Route>

                </Route>
              </Route>
              */}
            </Routes>
          </div>  
      {/*
      <h1 className="text-6xl font-bold underline">
      Laurin Markkinat
    </h1>
        <img src={logo} className="App-logo" alt="logo" />   
         */}                                        
    </>
  );
}

export default App;
