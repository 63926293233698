import { createContext, useContext , useReducer} from "react";
import MessageReducer from "./MessageReducer";
import { Toaster } from "../components/Toaster";



const MessageContext = createContext({});

export const useSpotContext = () => useContext(MessageContext);
export function MessageProvider({ children }) {
    const [msg, dispatch] = useReducer(MessageReducer,{});
    //const [spots, setSpots] = useState(undefined);    
    

    const controller = new AbortController();

    return (
      <MessageContext.Provider value={{ msg }}>
        <Toaster/>        
        {children}
      </MessageContext.Provider>
    );
  }
  
  // Custom hook for using ThemeContext
  export function useMessage() {
    const context = useContext(MessageContext);
    if (!context) throw new Error("useMessage must be used within a MessageProvider");
    return context;
  }