import { createContext, useContext, useState, useEffect } from "react";
import useAxios from '../api/axios';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        const storedData = localStorage.getItem("userData");
        return storedData ? JSON.parse(storedData) : "{}"; 
    });

    const api = useAxios();

    const addCompany = (newCompany) => {
        auth.companies.push(newCompany);
        setAuth(auth);      
    }

    const setSelectecCompanyID = (companyID) => {
        setAuth({...auth,selectedCompanyID: companyID});    
    }

    const deleteCompany = (companyID) => {

        const newAuth = {...auth, companies: auth.companies.filter(comp => comp.companyID !== companyID), selectedCompanyID: companyID === auth.selectedCompanyID ? null : auth.selectedCompanyID}
        setAuth(newAuth);
    }


    const getCompanies = async () => {
        
        var headers = tokenConfig();

        try {
            const response = await api.post('getMyCompanies', {}, headers);        
            //console.log("GET MY COMPANIES");
            //console.log(response.data);
            const companies = response.data;            
            
            setAuth({...auth,companies});
            //dispatch({type: MY_COMPANIES_LOADED,payload: res.data});        
        } catch(err) {
            //var errType = MY_COMPANIES_FAILED;
            if (err.response) {
                //dispatch(returnErrors(err.response.data.msg, err.response.status, 'MY_COMPANIES_LOADED'));
                if(err.response.status === 401) {
                //errType = AUTH_ERROR;
                }
            } 
            else if (err.data) {
                //dispatch(returnErrors(err.data.msg, err.data.htmlCode, 'MY_COMPANIES_LOADED'));
            }
            else {
                //dispatch(returnErrors("Odottomaton virhe: " + err.msg, 404, 'MY_COMPANIES_LOADED'));
            }
            
            //dispatch({ type: errType });    
            return null;    
        }
    }

        
  // Save to localStorage whenever data changes
  useEffect( () => {
    if(auth?.accessToken && !auth?.companies) {
        getCompanies();        
    }

    localStorage.setItem("userData", JSON.stringify(auth));
  }, [auth, auth.accessToken]);

  const tokenConfig = () => {
    // Get token from localstorage
    
    const token = auth.accessToken;
    //useSelector(state => state.auth.token); 
    //const token = localStorage.getItem('token');
    //token = getToken();
    // ;
  
    //console.log("TOKEN=" + token);
  
    // Headers
    const headers = {
      headers: {    
        'Content-type': 'application/json;charset=utf-8'    
    }};
  
    // If token, add to headers
    if (token) {
      headers.headers['X-Auth-Token'] = token;
    }
  
    return headers;
  };

    function isAdmin () {
        return auth?.isAdmin;
    }

    function isAuthenticated () {
        return auth?.user;
    }



    return (
        <AuthContext.Provider value={{auth, setAuth,isAdmin, deleteCompany,setSelectecCompanyID, addCompany,isAuthenticated}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;